import React, { useEffect, useState } from 'react';
import { ReactComponent as Piyard01Image3Svg } from '../assets/piyard01Image3.svg';

import { ReactComponent as Imagine0101Svg } from '../assets/imagine/0101.svg';
import { ReactComponent as Imagine0102Svg } from '../assets/imagine/0102.svg';
import { ReactComponent as Imagine0201Svg } from '../assets/imagine/0201.svg';
import { ReactComponent as Imagine0301Svg } from '../assets/imagine/0301.svg';
import { ReactComponent as Imagine0302Svg } from '../assets/imagine/0302.svg';
import { ReactComponent as Imagine0303Svg } from '../assets/imagine/0303.svg';
import { ReactComponent as Imagine0412Svg } from '../assets/imagine/0412.svg';

import Img01 from '../assets/imagine/0401.png';
import Img02 from '../assets/imagine/0402.png';
import Img03 from '../assets/imagine/0403.png';
import Img04 from '../assets/imagine/0404.png';
import Img05 from '../assets/imagine/0405.png';
import Img06 from '../assets/imagine/0406.png';
import Img07 from '../assets/imagine/0407.png';
import Img08 from '../assets/imagine/0408.png';
import Img09 from '../assets/imagine/0409.png';
import Img10 from '../assets/imagine/0410.png';
import Img11 from '../assets/imagine/0411.png';


function Imagine(props){
    const [part1, setPart1] = useState(false);
    const [part2, setPart2] = useState(false);
    const [part3, setPart3] = useState(false);
    const [part4, setPart4] = useState(false);
    const [init, setInit] = useState(false)

    const styles={
        fontStyle:{
            fontWeight:`700`,
            // fontFamily:`Microsoft YaHei`,
            fontSize:`2.3vw`,
            lineHeight:`40px`,
            color:`#1d1d1d`
        },
        content01:{
            display:`flex`
        },
        piyardFont:{
            margin:`0 0 8px`,
            height:`auto`, 
            width:`27vw`,
            minWidth:240, 
            maxWidth:480,
        },
        content01_text:{
            fontSize:`0.9vw`,
            lineHeight:`2.3`, 
            color:`#888`,
            width:`30vw`,
            minWidth:240, 
            maxWidth:`480px`,
            marginBottom:`200px`
        },
        piyard01Image:{
            height:`32vw`,
            width:`auto`,
            maxHeight:`550px`,
            marginTop:`80px`
        },
        title_03:{
            color:`#1d1d1d`,
            fontSize:`1.4vw`,
            lineHeight:`140%`,
            fontWeight:`bold`,
            marginBottom:`2.2vw`
        },
        content_03:{
            color:`#888`,
            fontSize:`0.95vw`,
            lineHeight:`200%`
        },
        svg_03:{
            width:`4.2vw`,
            height:`4.2vw`,
            marginBottom:`3vw`
        }

    }

    function fixAnchro(p1, p2, p3, p4){
        var t = document.documentElement.scrollTop || document.body.scrollTop;
        if(t<p1){
            window.p1 = false;
            window.p2 = false;
            window.p3 = false;
            window.p4 = false;
            setPart1(false);
            setPart2(false);
            setPart3(false);
            setPart4(false);
        }else
        if(t>=p1 && t<p2 ){
            window.p1 = true;
            window.p2 = false;
            window.p3 = false;
            window.p4 = false;
            setPart1(true);
            setPart2(false);
            setPart3(false);
            setPart4(false)
        }else
        if(t>=p2 && t<p3 ){
            window.p1 = false;
            window.p2 = true;
            window.p3 = false;
            window.p4 = false;
            setPart1(false);
            setPart2(true);
            setPart3(false);
            setPart4(false)
        }else
        if(t>=p3 && t<p4){
            window.p1 = false;
            window.p2 = false;
            window.p3 = true;
            window.p4 = false;
            setPart1(false);
            setPart2(false);
            setPart3(true);
            setPart4(false)
        }else
        if(t>=p4 ){
            window.p1 = false;
            window.p2 = false;
            window.p3 = false;
            window.p4 = true;
            setPart1(false);
            setPart2(false);
            setPart3(false);
            setPart4(true)
        }
    }

    function debonce(fn){
        var timer= null;
        return function(){
            var context = this;
            var args  = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args )
            }, 300)
        }
    }

    function resizeScreen(){
        let newP1 = window.p1?document.getElementById('part1_e').offsetTop - 44:document.getElementById('part1').offsetTop - 44;
        let newP2 = window.p2?document.getElementById('part2_e').offsetTop - 86:document.getElementById('part2').offsetTop -86;
        let newP3 = window.p3?document.getElementById('part3_e').offsetTop - 86:document.getElementById('part3').offsetTop -86;
        let newP4 = window.p4?document.getElementById('part4_e').offsetTop - 86:document.getElementById('part4').offsetTop -86;
        fixAnchro(newP1, newP2, newP3, newP4);
        document.onscroll=()=>{
            debonce(fixAnchro(newP1, newP2, newP3, newP4))
        }
    }

    useEffect(()=>{
        let part1 = document.getElementById('part1').offsetTop - 44;
        let part2 = document.getElementById('part2').offsetTop - 86;
        let part3 = document.getElementById('part3').offsetTop - 86;
        let part4 = document.getElementById('part4').offsetTop - 86;
        fixAnchro(part1,part2,part3,part4);
        document.onscroll=()=>{
            debonce(fixAnchro(part1, part2, part3, part4))
        }

        window.onresize=()=>{
            debonce(resizeScreen())
        }

        setInit(true);
    },[])

    useEffect(()=>{
        let state = props.history.location.state;
        if(state && state.location && init){
            let scrollTo;
            if(state.location === 'part1'){
                scrollTo = window.p1?document.getElementById('part1_e').offsetTop - 44:document.getElementById('part1').offsetTop - 44;
            }
            if(state.location === 'part2'){
                scrollTo = window.p2?document.getElementById('part2_e').offsetTop - 86:document.getElementById('part2').offsetTop - 86;
            }
            if(state.location === 'part3'){
                scrollTo = window.p3?document.getElementById('part3_e').offsetTop - 86:document.getElementById('part3').offsetTop - 86;
            }
            if(state.location === 'part4'){
                scrollTo = window.p4?document.getElementById('part4_e').offsetTop - 86:document.getElementById('part4').offsetTop - 86;
            }
            window.scrollTo(0,scrollTo)
        }
    },[props.history.location.state,init])

    return (
        <div>
            {/* 01 */}
            <div className="anchro" id="part1" style={part1?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`unset`}}>
                <div>Imagine</div>
                <div>01</div>
                <div></div>
            </div>
            {
                part1 &&
                <div id="part1_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content">
                <div></div>
                <div className="flex " style={{padding:`50px 0 200px 45px`}}>
                    <div className="flex" style={{flexDirection:`column`}}>
                        <Imagine0101Svg style={styles.piyardFont} />
                        <h3 style={{...styles.fontStyle, fontSize:`1.4vw`, marginBottom:70, maxWidth:452,marginTop:20}}>现代科技企业雇主品牌</h3>
                        <p style={{...styles.content01_text,maxWidth:`410px`,marginBottom:`8vw`}}>
                            深空裂变雇主品牌服务意在携手各科技类企业突围而出，既能集中企业主要精力进行科技研究及业务开发，也能达到通过提升雇主品牌效应从而提升人才吸引力及商业品牌吸引力。并根据各科技企业不同的阶段及需求，将雇主价值观实施落地的同时，提升对内的传播及认同感。为企业打造高效、专业和创新的雇主品牌及招聘服务。
                        </p>
                        <Piyard01Image3Svg style={{marginTop:`auto`,width:`9.587vw`,height:`auto`}} />   
                    </div>
                    <Imagine0102Svg style={{height:`40vw`,width:`auto`,margin:`30px 0 0 6vw`,alignSelf:`flex-end`}} />
                </div>
                <div></div>
            </div>
            {/* 02 */}
            <div className="anchro" id="part2" style={part2?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`3`}}>
                <div>Uniformity</div>
                <div>02</div>
                <div></div>
            </div>
            {
                part2 &&
                <div id="part2_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content"  style={{position:`relative`}}>
                <div></div>
                <div style={{padding:`50px 0 0px 0px`}}>
                    <div style={{...styles.content01,paddingLeft:45,paddingBottom:`10vw`, marginTop:92,width:`85%`,justifyContent:`space-between`,position:`relative`,paddingRight:`calc(15% - 45px)`,boxSizing:`content-box`}}>
                        <h3 style={{...styles.fontStyle, lineHeight:`140%`}}>
                        保持雇主&商业品牌<br/>的一致性
                        </h3>
                        <p style={{...styles.content01_text,maxWidth:730, width:`43%`,margin:0}}>
                        商业品牌与雇主品牌之间相互作用、相互影响，只有两者协调一致，才能达到一定的品牌效应。一个被现有员工接受的雇主品牌，不仅能够最大程度的帮助企业吸引潜在员工，它同样也可以帮助企业实现商业上的价值。
                        </p>
                        <Piyard01Image3Svg style={{position:`absolute`,width:`9.587vw`,right:`-4.7vw`,bottom:`-3.7vw`,height:`7.4vw`}} />   
                        <div style={{position:`absolute`,width:`48%`,right:`0`,bottom:`0`,height:`1px`,background:`#c0c0c0`}}></div>
                    </div>
                    <div className="flex justBetween alignStart" style={{paddingBottom:`15vw`,paddingLeft:110,width:`85%`,paddingTop:`10vw`}}>
                        <Imagine0201Svg style={{width:`18.6vw`,height:`auto`}} />
                        <div style={{width:`45%`,marginTop:`3vw`}}>
                            <div>
                                <h3 style={{fontSize:`1.4vw`,lineHeight:`200%`,fontWeight:`bold`,marginBottom:`1.4vw`}}>注重候选人体验</h3>
                                <p style={{fontSize:`0.95vw`,lineHeight:`200%`,color:`#888`,marginBottom:`5vw`}}>
                                    就如同商业品牌注重消费者对用户体验，在建设中雇主品牌过程中，我们也强调员工和潜在候选人的体验。
                                </p>
                            </div>
                            <div>
                                <h3 style={{fontSize:`1.4vw`,lineHeight:`200%`,fontWeight:`bold`,marginBottom:`1.4vw`}}>从市场细分打造差异化</h3>
                                <p style={{fontSize:`0.95vw`,lineHeight:`200%`,color:`#888`,marginBottom:`5vw`}}>
                                    找准雇主品牌定位，明确品牌在人力资源市场中的竞争范围和人才争夺目标，才是作出雇主品牌差异性的根本。
                                </p>
                            </div>
                            <div>
                                <h3 style={{fontSize:`1.4vw`,lineHeight:`200%`,fontWeight:`bold`,marginBottom:`1.4vw`}}>伞型品牌策略</h3>
                                <p style={{fontSize:`0.95vw`,lineHeight:`200%`,color:`#888`,marginBottom:`0`}}>
                                    制定统一的雇主品牌，并围绕此品牌开展校招、培训、文化建设，加强对雇主品牌的认知。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            {/* 03 */}
            <div className="anchro" id="part3" style={part3?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`4`}}> 
                <div>Colleges</div>
                <div>03</div>
                <div></div>
            </div>
            {
                part3 &&
                <div id="part3_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content" >
                <div></div>
                <div style={{padding:`50px 0 0 0px`, position:`relative`}}>
                    <h3 style={{...styles.fontStyle,marginTop:92,paddingLeft:45}}>建立雇主品牌</h3>
                    <div className="flex justBetween alignStart" style={{padding:`12vw 45px`}}>
                        <div style={{width:`25%`}}>
                            <Imagine0301Svg style={styles.svg_03} />
                            <h3 style={styles.title_03}>打造真实的雇主品牌</h3>
                            <p style={styles.content_03}>
                                以员工为核心，以体验为基础，与期望相结合，以恰当的方式表达提炼口号为起点，围绕EVP让员工理解雇主品牌建设的初衷，并让员工参与到雇主品牌建设的过程中。将品牌主张和价值观融入到企业的管理，确保EVP得到有效的沟通和兑现。
                            </p>
                        </div>
                        <div style={{width:`25%`}}>
                            <Imagine0302Svg style={styles.svg_03} />
                            <h3 style={styles.title_03}>多媒体渠道宣传</h3>
                            <p style={styles.content_03}>
                                社交网络是建立雇主品牌中主要渠道之一。按照渠道、内容、传播三个点，明确目标人群，制定内容计划，将雇主品牌精准地传递给候选人。以候选人为中心，切合企业文化的基调及精神进行内容规划。
                            </p>
                        </div>
                        <div style={{width:`25%`}}>
                            <Imagine0303Svg style={styles.svg_03} />
                            <h3 style={styles.title_03}>吸引目标人才</h3>
                            <p style={styles.content_03}>
                                注重Z时代中人才群体的个性特征，通过晋升机会，挑战性，公司文化和价值观等等角度，对照马斯洛需求设EVP，从各方面满足人才的需求，激励内部员工并吸引外部人才。阐述企业能为他们提供的价值，人文关怀和情感关怀。
                            </p>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            {/* 04 */}
            <div className="anchro blackBackground grayBorderBottom" id="part4" style={part4?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`5`}}>
                <div className='whiteFont'>Case</div>
                <div className='whiteFont grayBorder'>04</div>
                <div className='whiteFont'></div>
            </div>
            {
                part4 &&
                <div id="part4_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content blackBackground">
                <div></div>
                <div className='grayBorder' style={{padding:0}}>
                    <div className='flex justBetween alignStart' style={{marginTop:142,marginBottom:`6vw`,padding:`0 45px`}}>
                        <h3 style={{...styles.fontStyle,marginTop:0,paddingLeft:0,color:`#fff`}}>案例</h3>
                        <Imagine0412Svg style={{width:`3.35vw`,height:`3.35vw`}} />
                    </div>
                    <div className='imagine04_content'>
                        <div className='flex'>
                            <img src={Img01} style={{width:`33.33%`}} />
                            <img src={Img02} style={{width:`33.33%`}} />
                            <img src={Img03} style={{width:`33.33%`}} />
                        </div>
                        <div className='flex'>
                            <div style={{width:`66.66%`}}>
                                <img src={Img04} style={{width:`100%`}} />
                            </div>
                            <div style={{width:`33.33%`}}>
                                <img src={Img05} style={{width:`100%`}} />
                                <img src={Img09} style={{width:`100%`}} />
                            </div>
                        </div>
                        <div className='flex'>
                            <div style={{width:`33.33%`}}>
                                <img src={Img07} style={{width:`100%`}} />
                                <img src={Img08} style={{width:`100%`}} />
                            </div>
                            <div style={{width:`66.66%`}}>
                                <img src={Img06} style={{width:`100%`}} />
                            </div>
                        </div>
                        <img src={Img10} style={{width:`10vw`,position:`absolute`,right:`-10vw`,top:`15%`}} />
                        <img src={Img11} style={{width:`10vw`,position:`absolute`,right:`-10vw`,top:`50%`}} />
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default Imagine;