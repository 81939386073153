import { ReactComponent as LogoSvg } from '../../assets/logo.svg';
import { ReactComponent as DrawerTxtSvg } from '../../assets/drawerTxt.svg';
import { ReactComponent as DrawerTxt2Svg } from '../../assets/drawerTxt2.svg';
import { ReactComponent as DrawerLineSvg } from '../../assets/drawerLine.svg';
import { ReactComponent as ArrowRight } from '../../assets/arrow_right.svg';
import React, { useEffect, useState } from 'react';
import { Drawer, Button } from 'antd';
import {MenuOutlined, CloseOutlined} from '@ant-design/icons';
import { ROUTE_HOME, ROUTE_CONTACTUS, ROUTE_IMAGINE, ROUTE_PILAND, ROUTE_PIYARD, ROUTE_RESPONSIBILITY } from '../../constant/routeConstant';


function Header(props){
    const [visible, setVisible] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState('-1')
    
    function openDrawer(){
        setVisible(true)
    }

    function closeDrawer(){
        setVisible(false)
    }

    function handleClickTab(index, path){
        closeDrawer()
        setActiveTab(index);
        props.history.push(path)
    }


    const styles= {
        drawer_content_text:{
            position:`absolute`,
            bottom:`1.8vw`,
            left:`1.8vw`,
            right:`1.8vw`
        },
        drawer_content_text_title:{
            fontSize:`1.8vw`, 
            fontWeight:`700`, 
            // fontFamily:`Montserrat`,
            // lineHeight:`40px`,
            marginBottom:`0.5vw`,
            display:`block`
        },
        drawer_content_text_p:{
            fontSize:`1.3vw`,
            fontWeight:`700`,
            // fontFamily:`Microsoft YaHei`,
            margin:0,
            display:`flex`,
            justifyContent: `space-between`,
            alignItems: `center`
            // lineHeight:`28px`
        },
        drawer_content_text_num:{
            fontSize:`1.5vw`,
            display:`block`, 
            textAlign:`right`,
        },
        drawer_content_text_line:{
            display:`block`, 
            textAlign:`right`
        },
        drawer_content_container:{
            position:`relative`,
            padding:`1.8vw`,
            flex:`1`, 
            borderBottom: `1px solid #808080`, 
        }
    }

    function renderDrawerTitle(){
        return (
            <div  className="mainHeader" style={{borderBottom:`1px solid #616161`, background:`#1D1D1D`, position:`unset`}}>
                <div  className="logo">
                    <LogoSvg style={{width:`100%`}} fill='#fff' />
                </div>
                <div  className="nav" style={{borderLeft:`1px solid #616161`,borderRight:`1px solid #616161`}}>
                    
                </div>
                <div  className="menuButton">
                    <Button 
                        onClick={closeDrawer} 
                        style={{background:`#EB483F`,border:`none`,width:60,height:60}} 
                        icon={<CloseOutlined style={{color:`#fff`}} />}
                    >
                    </Button>
                </div>
            </div>
        )
    }

    useEffect(()=>{
        let pathname = props.history.location.pathname;
        if(pathname === ROUTE_HOME)setActiveTab('0');
        if(pathname === ROUTE_PIYARD)setActiveTab('1');
        if(pathname === ROUTE_IMAGINE)setActiveTab('2');
        if(pathname === ROUTE_PILAND)setActiveTab('3');
        if(pathname === ROUTE_RESPONSIBILITY)setActiveTab('4');
        if(pathname === ROUTE_CONTACTUS)setActiveTab('5');
    })
    // console.log(props)
    return (
        <>
            <div style={{padding:`43px 0`}}></div>
            <div  
                className="mainHeader" 
                style={{
                    background:props.history.location.pathname === ROUTE_CONTACTUS?`#1d1d1d`:`#e5e5e5`,
                    borderBottom:props.history.location.pathname === ROUTE_CONTACTUS?`1px solid #5a5a5a`:`1px solid #c0c0c0`
                }}
            >
                <div  className="logo">
                    <LogoSvg 
                        fill={props.history.location.pathname === ROUTE_CONTACTUS?'#fff':`auto`}
                        onClick={()=>{props.history.push(ROUTE_HOME)}} 
                        style={{width:`100%`,cursor:`pointer`}} 
                    />
                </div>
                <div  className="nav" style={props.history.location.pathname === ROUTE_CONTACTUS?{borderRight:`1px solid #5a5a5a`,borderLeft:`1px solid #5a5a5a`}:{}}>
                    <ul>
                        <li 
                            onClick={()=>handleClickTab('0', ROUTE_HOME)} 
                            style={{
                                color:activeTab==='0'?
                                `#EB483F`
                                :
                                props.history.location.pathname === ROUTE_CONTACTUS?
                                `#fff`
                                :
                                `#1D1D1D`
                            }}
                        >
                            Home
                        </li>
                        <li 
                            onClick={()=>handleClickTab('1', ROUTE_PIYARD)} 
                            style={{
                                color:activeTab==='1'?
                                `#EB483F`
                                :
                                props.history.location.pathname === ROUTE_CONTACTUS?
                                `#fff`
                                :
                                `#1D1D1D`
                            }}
                        >
                            Pi Yard</li>
                        <li 
                            onClick={()=>handleClickTab('2', ROUTE_IMAGINE)} 
                            style={{
                                color:activeTab==='2'?
                                `#EB483F`
                                :
                                props.history.location.pathname === ROUTE_CONTACTUS?
                                `#fff`
                                :
                                `#1D1D1D`
                            }}
                        >
                            Imagine</li>
                        <li 
                            onClick={()=>handleClickTab('3', ROUTE_PILAND)} 
                            style={{
                                color:activeTab==='3'?
                                `#EB483F`
                                :
                                props.history.location.pathname === ROUTE_CONTACTUS?
                                `#fff`
                                :
                                `#1D1D1D`
                            }}
                        >
                            Pi Land</li>
                        <li 
                            onClick={()=>handleClickTab('4', ROUTE_RESPONSIBILITY)} 
                            style={{
                                color:activeTab==='4'?
                                `#EB483F`
                                :
                                props.history.location.pathname === ROUTE_CONTACTUS?
                                `#fff`
                                :
                                `#1D1D1D`
                            }}
                        >
                            社会责任
                        </li>
                        <li 
                            onClick={()=>handleClickTab('5', ROUTE_CONTACTUS)} 
                            style={{
                                color:activeTab==='5'?
                                `#EB483F`
                                :
                                props.history.location.pathname === ROUTE_CONTACTUS?
                                `#fff`
                                :
                                `#1D1D1D`
                            }}
                        >
                            联系我们
                        </li>
                    </ul>
                </div>
                <div  className="menuButton">
                    <Button onClick={openDrawer} style={{background:`#EB483F`,border:`none`,width:60,height:60}} icon={<MenuOutlined style={{color:`#fff`}} />}></Button>
                    <Drawer 
                        headerStyle={{padding:0, borderBottom:0}} 
                        bodyStyle={{background:`#1D1D1D`, position:`relative`, padding:`0`}}
                        width={`100%`} 
                        height={`100%`}
                        title={renderDrawerTitle()} 
                        closable={false} 
                        placement="top" 
                        onClose={closeDrawer} 
                        visible={visible}
                    >
                        <DrawerTxtSvg style={{position:`absolute`,top:`42px`,left:`4vw`, width:`65vw`, maxWidth:`1000px`, zIndex:`1`}}/>
                        <DrawerTxt2Svg style={{position:`absolute`,bottom:`42px`,right:`4vw`, width:`65vw`, maxWidth:`1000px`, zIndex:`1`}} />
                        <p style={{position:`absolute`,bottom:`42px`,left:`4vw`, color:`#fff`, fontSize:14, width:150, textTransform:`uppercase`, zIndex:`1` }}>A fun & problem-solving company</p>
                        <div 
                            style={{
                                position:`absolute`,
                                zIndex:`2`,
                                left:`7vw`,
                                right:`4vw`,
                                top:`128px`,
                                color:`#fff`,
                                maxHeight:`50vw`,
                                height:`548px`,
                                display:`flex`,
                                borderTop:`4px solid #EB483F`
                            }}
                        >
                            <div onClick={()=>handleClickTab('0',ROUTE_PIYARD)} className="drawer_content_container" style={{...styles.drawer_content_container,height:`65%`, borderLeft:`1px solid #808080`}}>
                                <span style={styles.drawer_content_text_num}>01</span>
                                <span style={styles.drawer_content_text_line}>
                                    <DrawerLineSvg style={{width:`1.5vw`}} />
                                </span>
                                <div style={styles.drawer_content_text}>
                                    <span style={styles.drawer_content_text_title}>PI YARD</span>
                                    <p style={styles.drawer_content_text_p}>
                                        <span>校招匹配&服务平台</span>
                                        <ArrowRight className='drawer_content_arrow' />
                                    </p>
                                </div>
                                <div className='boxAnimation'></div>
                            </div>
                            <div onClick={()=>handleClickTab('2',ROUTE_PILAND)} className="drawer_content_container" style={{...styles.drawer_content_container,height:`75%`, borderLeft:`1px solid #808080`}}>
                                <span style={styles.drawer_content_text_num}>02</span>
                                <span style={styles.drawer_content_text_line}>
                                    <DrawerLineSvg style={{width:`1.5vw`}} />
                                </span>
                                <div style={styles.drawer_content_text}>
                                    <span style={styles.drawer_content_text_title}>PI LAND</span>
                                    <p style={styles.drawer_content_text_p}>
                                        <span>中高端科技人才猎寻</span>
                                        <ArrowRight className='drawer_content_arrow' />
                                    </p>
                                </div>
                                <div className='boxAnimation'></div>
                            </div>
                            <div onClick={()=>handleClickTab('1',ROUTE_IMAGINE)} className="drawer_content_container" style={{...styles.drawer_content_container,height:`100%`,borderLeft:`1px solid #808080`,borderRight:`1px solid #808080`}}>
                                <span style={styles.drawer_content_text_num}>03</span>
                                <span style={styles.drawer_content_text_line}>
                                    <DrawerLineSvg style={{width:`1.5vw`}} />
                                </span>
                                <div style={styles.drawer_content_text}>
                                    <span style={styles.drawer_content_text_title}>IMAGINE</span>
                                    <p style={styles.drawer_content_text_p}>
                                        <span>科技企业雇主品牌服务</span>
                                        <ArrowRight className='drawer_content_arrow' />
                                    </p>
                                </div>
                                <div className='boxAnimation'></div>
                            </div>
                            <div onClick={()=>handleClickTab('3',ROUTE_RESPONSIBILITY)} className="drawer_content_container" style={{...styles.drawer_content_container,height:`58%`,borderRight:`1px solid #808080`}}>
                                <span style={styles.drawer_content_text_num}>04</span>
                                <span style={styles.drawer_content_text_line}>
                                    <DrawerLineSvg style={{width:`1.5vw`}} />
                                </span>
                                <div style={styles.drawer_content_text}>
                                    <span style={styles.drawer_content_text_title}>RESPONSIBILITY</span>
                                    <p style={styles.drawer_content_text_p}>
                                        <span>社会责任</span>
                                        <ArrowRight className='drawer_content_arrow' />
                                    </p>
                                </div>
                                <div className='boxAnimation'></div>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </div>
        </>
    )
}

export default Header;