import Header from "./command/Header";
import React, { useEffect, useState } from 'react';
import { ReactComponent as Contact0101Svg } from '../assets/contact/0101.svg';
import { ReactComponent as Contact0102Svg } from '../assets/contact/0102.svg';
import { ReactComponent as Contact0103Svg } from '../assets/contact/0103.svg';
import { ReactComponent as Contact0104Svg } from '../assets/contact/0104.svg';
import { ReactComponent as Res0106Svg } from '../assets/res/0106.svg';

import { ReactComponent as Piyard01Image3Svg } from '../assets/piyard01Image3.svg';

import { Input, Button, message } from 'antd';
import axios from "axios";

function Contact(props){
    const [part1, setPart1] = useState(false);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [remark, setRemark] = useState('');

    const styles={
        fontStyle:{
            fontWeight:`700`,
            // fontFamily:`Microsoft YaHei`,
            fontSize:`2.3vw`,
            lineHeight:`40px`,
            color:`#1d1d1d`
        },
        content01:{
            display:`flex`
        },
        piyardFont:{
            margin:`0 0 8px`,
            height:`auto`, 
            width:`27vw`,
            minWidth:240, 
            maxWidth:480,
        },
        content01_text:{
            fontSize:`0.9vw`,
            lineHeight:`2.3`, 
            color:`#888`,
            width:`30vw`,
            minWidth:240, 
            maxWidth:`480px`,
            marginBottom:`200px`
        },
        piyard01Image:{
            height:`32vw`,
            width:`auto`,
            maxHeight:`550px`,
            marginTop:`80px`
        },

    }

    function handleClickButton(){
        if(!name){
            message.error('姓名不能为空');
            return;
        }
        if(!mobile){
            message.error('手机不能为空');
            return;
        }
        if(!email){
            message.error('邮箱不能为空');
            return;
        }
        if(!companyName){
            message.error('企业名称不能为空');
            return;
        }
        let obj = {
            name:name,
            mobile:mobile,
            email:email,
            companyName:companyName,
            remark:remark
        }
        console.log(JSON.stringify(obj));
        let url = 'http://recruit_admin.gdmax.cn/v1/mini/UserFeedback';
        axios.post(url,{feedback_content:JSON.stringify(obj)})
        .then(res=>{
            if(res && res.data && res.data.result){
                setName('');
                setMobile('');
                setEmail('');
                setCompanyName('');
                setRemark('');
                message.success('提交成功');
            }
        })
        .catch(error=>{
            message.error('提交失败,请稍后再试')
        })
    }

    function handleInputChange(e,type){
        if(type === 'name'){
            setName(e.target.value)
        }
        if(type === 'mobile'){
            setMobile(e.target.value)
        }
        if(type === 'email'){
            setEmail(e.target.value)
        }
        if(type === 'companyName'){
            setCompanyName(e.target.value)
        }
        if(type === 'remark'){
            setRemark(e.target.value)
        }
    }

    function handleClickLabel(name){
        console.log(document.getElementById(name))
        // document.getElementById(name).focus()
    }

    return (
        <div>
            <div className="anchro" style={{borderBottom:`1px solid #5a5a5a`,background:`#1d1d1d`}}>
                <div></div>
                <div className="flex" style={{padding:0,borderRight:`1px solid #5A5A5A`,borderLeft:`1px solid #5A5A5A`}}>
                    <div style={{flex:`1`,borderRight:`1px solid #5A5A5A`}}></div>
                    <div style={{flex:`1`}}></div>
                </div>
                <div></div>
            </div>
            <div className="anchro_content" style={{background:`#1d1d1d`}}>
                <div style={{borderBottom:`1px solid #5a5a5a`}}></div>
                <div className="flex" style={{padding:0,borderRight:`1px solid #5A5A5A`,borderLeft:`1px solid #5A5A5A`,borderBottom:`1px solid #5a5a5a`}}>
                    <div style={{flex:`1`,borderRight:`1px solid #5A5A5A`}}>
                        <Contact0101Svg style={{width:`27.3vw`,height:`auto`,marginBottom:`4.3vw`,marginTop:`11.5vw`}} />
                        <p style={{color:`#a0a0a0`,width:`27.3vw`,lineHeight:`200%`,fontSize:`0.96vw`}}>
                            我们对多样性、公平和包容性的承诺是建立非凡团队的关键。我们雇佣了才华横溢、能力和潜力非凡的人，并创造一个可以成为自己最佳版本的协作环境。
                        </p>
                    </div>
                    <div style={{flex:`1`}}>
                        <Contact0102Svg style={{width:`100%`,height:`auto`,margin:`8.8vw 0`}} />
                    </div>
                </div>
                <div style={{borderBottom:`1px solid #5a5a5a`}}></div>
            </div>
            <div className="anchro" style={{borderBottom:`1px solid #5a5a5a`,background:`#1d1d1d`}}>
                <div ></div>
                <div className="flex" style={{padding:0,borderRight:`1px solid #5A5A5A`,borderLeft:`1px solid #5A5A5A`}}>
                    <div style={{flex:`1`,borderRight:`1px solid #5A5A5A`}}></div>
                    <div style={{flex:`1`}}></div>
                </div>
                <div></div>
            </div>

            <div className="anchro" style={{height:`195px`}}>
                <div></div>
                <div className="flex" style={{padding:0,borderRight:`1px solid #c0c0c0`,borderLeft:`1px solid #c0c0c0`}}>
                    <div style={{flex:`1`,borderRight:`1px solid #c0c0c0`}}></div>
                    <div className="flex alignCenter justEnd" style={{flex:`1`}}>
                        <Res0106Svg style={{marginRight:`47px`,width:`3.35vw`,height:`auto`}} />
                    </div>
                </div>
                <div></div>
            </div>
            <div className="anchro" style={{height:`auto`}}>
                <div></div>
                <div className="flex" style={{padding:0,borderRight:`1px solid #c0c0c0`,borderLeft:`1px solid #c0c0c0`}}>
                    <div style={{flex:`1`,borderRight:`1px solid #c0c0c0`}}>
                        <div className="flex justBetween alignCenter" style={{padding:`24px`,background:`#222`}}>
                            <span style={{color:`#fff`,fontSize:`22px`,fontWeight:`bold`}}>需求咨询</span>
                            <Contact0104Svg />
                        </div>
                        <ul>
                            <li className="flex" style={{padding:`80px 24px 0px`,borderBottom:`1px solid #888`}}>
                                <label htmlFor="name" style={{width:90,color:`#888`}}>姓名</label>
                                <Input id="name" bordered={false} className="inputStyle" value={name} onChange={(e)=>handleInputChange(e,'name')} />
                            </li>
                            <li className="flex" style={{padding:`80px 24px 0px`,borderBottom:`1px solid #888`}}>
                                <label htmlFor="mobile" style={{width:90,color:`#888`}}>手机</label>
                                <Input id="mobile" bordered={false} className="inputStyle" value={mobile} onChange={(e)=>handleInputChange(e,'mobile')} />
                            </li>
                            <li className="flex" style={{padding:`80px 24px 0px`,borderBottom:`1px solid #888`}}>
                                <label htmlFor="email" style={{width:90,color:`#888`}}>邮箱</label>
                                <Input id="email" bordered={false} className="inputStyle" value={email} onChange={(e)=>handleInputChange(e,'email')} />
                            </li>
                            <li className="flex" style={{padding:`80px 24px 0px`,borderBottom:`1px solid #888`}}>
                                <label htmlFor="companyName" style={{width:90,color:`#888`}}>企业名称</label>
                                <Input id="companyName" bordered={false} className="inputStyle" value={companyName} onChange={(e)=>handleInputChange(e,'companyName')} />
                            </li>
                            <li className="flex" style={{padding:`80px 24px 0px`,borderBottom:`1px solid #888`}}>
                                <label htmlFor="remark" style={{width:90,color:`#888`}}>备注</label>
                                <Input id="remark" bordered={false} className="inputStyle" value={remark} onChange={(e)=>handleInputChange(e,'remark')} />
                            </li>
                        </ul>

                        <Button style={{display:`block`,margin:`120px 0 0 auto`}} className="buttonStyle" type="primary" onClick={handleClickButton}>确认发送</Button>
                    </div>
                    <div style={{flex:`1`}}>
                        <Contact0103Svg style={{display:`block`,width:`auto`,height:`38.9vw`,margin:`47px 47px 6vw auto`}} />
                        <Piyard01Image3Svg style={{display:`block`,width:`auto`,height:`7.4vw`,margin:`0px 47px 13.5vw auto`}} />
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default Contact;