import Header from "./command/Header";
import React, { useEffect, useState } from 'react';
import { ReactComponent as PiyardFontSvg } from '../assets/piyardFont.svg';
import { ReactComponent as Piyard01ImageSvg } from '../assets/piyard01Image.svg';
import { ReactComponent as Piyard01Image2Svg } from '../assets/piyard01Image2.svg';
import { ReactComponent as Piyard01Image3Svg } from '../assets/piyard01Image3.svg';

import { ReactComponent as Piyard0310Svg } from '../assets/piyard/0310.svg';
import { ReactComponent as Piyard0311Svg } from '../assets/piyard/0311.svg';

import { ReactComponent as Piyard0401Svg } from '../assets/piyard/0401.svg';
import { ReactComponent as Piyard0402Svg } from '../assets/piyard/0402.svg';
import { ReactComponent as Piyard0403Svg } from '../assets/piyard/0403.svg';
import { ReactComponent as Piyard0404Svg } from '../assets/piyard/0404.svg';
import { ReactComponent as Piyard0405Svg } from '../assets/piyard/0405.svg';
import { ReactComponent as Piyard0406Svg } from '../assets/piyard/0406.svg';
import { ReactComponent as Piyard0407Svg } from '../assets/piyard/0407.svg';

import Img01 from '../assets/piyard/0201.png';
import Img02 from '../assets/piyard/0202.png';
import Img03 from '../assets/piyard/0203.png';
import Img04 from '../assets/piyard/0204.png';
import Img05 from '../assets/piyard/0205.png';
import Img06 from '../assets/piyard/0206.png';
import Img07 from '../assets/piyard/0207.png';
import Img08 from '../assets/piyard/0208.png';
import Img09 from '../assets/piyard/0209.png';
import Img10 from '../assets/piyard/0210.png';
import Img11 from '../assets/piyard/0211.png';
import Img12 from '../assets/piyard/0212.png';
import Img01w from '../assets/piyard/0201_w.png';
import Img02w from '../assets/piyard/0202_w.png';
import Img03w from '../assets/piyard/0203_w.png';
import Img04w from '../assets/piyard/0204_w.png';
import Img05w from '../assets/piyard/0205_w.png';
import Img06w from '../assets/piyard/0206_w.png';
import Img07w from '../assets/piyard/0207_w.png';
import Img08w from '../assets/piyard/0208_w.png';
import Img09w from '../assets/piyard/0209_w.png';
import Img10w from '../assets/piyard/0210_w.png';
import Img11w from '../assets/piyard/0211_w.png';
import Img12w from '../assets/piyard/0212_w.png';

import Img0301 from '../assets/piyard/0301.png';
import Img0302 from '../assets/piyard/0302.png';
import Img0303 from '../assets/piyard/0303.png';
import Img0304 from '../assets/piyard/0304.png';
import Img0305 from '../assets/piyard/0305.png';
import Img0306 from '../assets/piyard/0306.png';
import Img0307 from '../assets/piyard/0307.png';
import Img0308 from '../assets/piyard/0308.png';
import Img0309 from '../assets/piyard/0309.png';

import Img0401 from '../assets/piyard/0401.png';


function PiYard(props){
    const [part1, setPart1] = useState(false);
    const [part2, setPart2] = useState(false);
    const [part3, setPart3] = useState(false);
    const [part4, setPart4] = useState(false);
    const [init, setInit] = useState(false)
    
    const styles={
        fontStyle:{
            fontWeight:`700`,
            // fontFamily:`Microsoft YaHei`,
            fontSize:`2.3vw`,
            lineHeight:`40px`,
            color:`#1d1d1d`
        },
        content01:{
            display:`flex`
        },
        piyardFont:{
            margin:`0 0 8px`,
            height:`auto`, 
            width:`27vw`,
            minWidth:240, 
            maxWidth:480,
        },
        content01_text:{
            fontSize:`0.9vw`,
            lineHeight:`2.3`, 
            color:`#888`,
            width:`30vw`,
            minWidth:240, 
            maxWidth:`480px`,
            marginBottom:`200px`
        },
        piyard01Image:{
            height:`32vw`,
            width:`auto`,
            maxHeight:`550px`,
            marginTop:`80px`
        },

    }

    function fixAnchro(p1, p2, p3, p4){
        var t = document.documentElement.scrollTop || document.body.scrollTop;
        if(t<p1){
            window.p1 = false;
            window.p2 = false;
            window.p3 = false;
            window.p4 = false;
            setPart1(false);
            setPart2(false);
            setPart3(false);
            setPart4(false);
        }else
        if(t>=p1 && t<p2 ){
            window.p1 = true;
            window.p2 = false;
            window.p3 = false;
            window.p4 = false;
            setPart1(true);
            setPart2(false);
            setPart3(false);
            setPart4(false)
        }else
        if(t>=p2 && t<p3 ){
            window.p1 = false;
            window.p2 = true;
            window.p3 = false;
            window.p4 = false;
            setPart1(false);
            setPart2(true);
            setPart3(false);
            setPart4(false)
        }else
        if(t>=p3 && t<p4){
            window.p1 = false;
            window.p2 = false;
            window.p3 = true;
            window.p4 = false;
            setPart1(false);
            setPart2(false);
            setPart3(true);
            setPart4(false)
        }else
        if(t>=p4 ){
            window.p1 = false;
            window.p2 = false;
            window.p3 = false;
            window.p4 = true;
            setPart1(false);
            setPart2(false);
            setPart3(false);
            setPart4(true)
        }
    }

    function debonce(fn){
        var timer= null;
        return function(){
            var context = this;
            var args  = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args )
            }, 300)
        }
    }

    function resizeScreen(){
        let newP1 = window.p1?document.getElementById('part1_e').offsetTop - 44:document.getElementById('part1').offsetTop - 44;
        let newP2 = window.p2?document.getElementById('part2_e').offsetTop - 86:document.getElementById('part2').offsetTop -86;
        let newP3 = window.p3?document.getElementById('part3_e').offsetTop - 86:document.getElementById('part3').offsetTop -86;
        let newP4 = window.p4?document.getElementById('part4_e').offsetTop - 86:document.getElementById('part4').offsetTop -86;
        fixAnchro(newP1, newP2, newP3, newP4);
        document.onscroll=()=>{
            debonce(fixAnchro(newP1, newP2, newP3, newP4))
        }
    }

    useEffect(()=>{
        let part1 = document.getElementById('part1').offsetTop - 44;
        let part2 = document.getElementById('part2').offsetTop - 86;
        let part3 = document.getElementById('part3').offsetTop - 86;
        let part4 = document.getElementById('part4').offsetTop - 86;
        
        fixAnchro(part1,part2,part3,part4);
        document.onscroll=()=>{
            debonce(fixAnchro(part1, part2, part3, part4))
        }
        
        window.onresize=()=>{
            debonce(resizeScreen())
        }
        setInit(true);
    },[])

    useEffect(()=>{
        let state = props.history.location.state;
        if(state && state.location && init){
            let scrollTo;
            if(state.location === 'part1'){
                scrollTo = window.p1?document.getElementById('part1_e').offsetTop - 44:document.getElementById('part1').offsetTop - 44;
            }
            if(state.location === 'part2'){
                scrollTo = window.p2?document.getElementById('part2_e').offsetTop - 86:document.getElementById('part2').offsetTop - 86;
            }
            if(state.location === 'part3'){
                scrollTo = window.p3?document.getElementById('part3_e').offsetTop - 86:document.getElementById('part3').offsetTop - 86;
            }
            if(state.location === 'part4'){
                scrollTo = window.p4?document.getElementById('part4_e').offsetTop - 86:document.getElementById('part4').offsetTop - 86;
            }
            window.scrollTo(0,scrollTo)
        }
    },[props.history.location.state,init])

    return (
        <div>
            {/* 01 */}
            <div className="anchro" id="part1" style={part1?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`unset`}}>
                <div>Pi Yard</div>
                <div>01</div>
                <div></div>
            </div>
            {
                part1 &&
                <div id="part1_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content">
                <div></div>
                <div style={{padding:`50px 0 200px 45px`}}>
                    <PiyardFontSvg style={styles.piyardFont} />
                    <h3 style={{...styles.fontStyle, fontSize:`1.4vw`, marginBottom:70, maxWidth:452}}>Z世代高科技人才的精准定位&智能匹配 人力资源高科技平台</h3>
                    <div style={styles.content01}>
                        <div style={{marginRight:`5vw`,flexDirection:`column`,display:`flex`}}>
                            <p style={{...styles.content01_text,marginBottom:0}}>
                                Pi Yard是Z世代IT人才的招聘匹配&服务平台。Z世代更重视工作环境、团队文化及个人发展空间。除却基本硬技能的高度智能化匹配，Pi Yard平台会接入雇主各项软技能作为匹配要素，以提高工作配对成功率。我们主力协助雇主找到理想员工。接入雇主需求后，团队会将雇主的信息及需求进行全方位整合和修饰，并为雇主发出最具吸引力的招聘广告，从而抢占Z世代IT人才的目光，对工作产生兴趣。
                            </p>
                            <Piyard01Image3Svg style={{marginTop:`auto`,height:`auto`,width:`9.587vw`}} />
                        </div>
                        <div>
                            <Piyard01ImageSvg style={{...styles.piyard01Image}} />
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            {/* 02 */}
            <div className="anchro" id="part2" style={part2?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`3`}}>
                <div>Industries</div>
                <div>02</div>
                <div></div>
            </div>
            {
                part2 &&
                <div id="part2_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content"  style={{position:`relative`}}>
                <div></div>
                <div style={{padding:`50px 0 0px 0px`}}>
                    <h3 style={{...styles.fontStyle, marginBottom:70, marginTop:92,paddingLeft:45}}>行业人才池</h3>
                    <div>
                        <div style={{paddingLeft:45}}>
                            <p style={{...styles.content01_text,maxWidth:730, width:`95%`,marginBottom:97}}>
                                我们服务的企业包括但不限于各种科技行业领域新兴企业，给对标企业输送对标专业的人才。搭建内推与校园大使渠道，可优先触达毕业生群体，助力企业获取优秀人才简历。
                            </p>
                        </div>
                        <div style={{background:`#dcdcdc`,padding:`60px 0`}}>
                            <ul className="piyard02Ul">
                                <li>
                                    <img src={Img01}  className="img_r" />
                                    <img src={Img01w} className="img_w" />
                                    <span>新能源</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img02}  className="img_r" />
                                    <img src={Img02w} className="img_w" />
                                    <span>大数据</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img03}  className="img_r" />
                                    <img src={Img03w} className="img_w" />
                                    <span>物联网</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img04}  className="img_r" />
                                    <img src={Img04w} className="img_w" />
                                    <span>金融科技</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img05}  className="img_r" />
                                    <img src={Img05w} className="img_w" />
                                    <span>智能制造</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img06}  className="img_r" />
                                    <img src={Img06w} className="img_w" />
                                    <span>机器人</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img07}  className="img_r" />
                                    <img src={Img07w} className="img_w" />
                                    <span>互联网</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img08}  className="img_r" />
                                    <img src={Img08w} className="img_w" />
                                    <span>芯片</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img09}  className="img_r" />
                                    <img src={Img09w} className="img_w" />
                                    <span>VR/AR</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img10}  className="img_r" />
                                    <img src={Img10w} className="img_w" />
                                    <span>智能医疗</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img11}  className="img_r" />
                                    <img src={Img11w} className="img_w" />
                                    <span>新材料</span>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img12}  className="img_r" />
                                    <img src={Img12w} className="img_w" />
                                    <span>人工智能</span>
                                    <div className="boxAnimation"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            {/* 03 */}
            <div className="anchro" id="part3" style={part3?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`4`}}> 
                <div>Colleges</div>
                <div>03</div>
                <div></div>
            </div>
            {
                part3 &&
                <div id="part3_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content" >
                <div></div>
                <div style={{padding:`50px 0 0 0px`, position:`relative`}}>
                    <h3 style={{...styles.fontStyle, marginBottom:38, marginTop:92,paddingLeft:45}}>校园大使网络直达全球上千家高校</h3>
                    <div style={{...styles.content01,paddingLeft:45}}>
                        <div >
                            <p style={{...styles.content01_text,maxWidth:730, width:`100%`,marginBottom:97}}>
                            覆盖全球300+所高校：广泛深入的高校合作伙伴，素质优质的粉丝群体
                            </p>
                        </div>
                    </div>
                    <div style={{position:`relative`,paddingBottom:`20vw`,paddingLeft:45,borderBottom:`1px solid #c0c0c0`}}>
                        <div>
                            <ul className="piyard03Ul">
                                <li><img src={Img0301} /></li>
                                <li><img src={Img0302} /></li>
                                <li><img src={Img0303} /></li>
                                <li><img src={Img0304} /></li>
                                <li><img src={Img0305} /></li>
                                <li><img src={Img0306} /></li>
                                <li><img src={Img0307} /></li>
                                <li><img src={Img0308} /></li>
                                <li><img src={Img0309} /></li>
                            </ul>
                        </div>
                        <Piyard0310Svg style={{position:`absolute`,bottom:1,right:`4vw`,width:`35vw`,height:`auto`}} />
                    </div>
                    <div style={{paddingRight:45,textAlign:`right`,position:`relative`,paddingBottom:`40vw`}}>
                        <h3 style={{...styles.fontStyle, marginBottom:38, marginTop:92,paddingLeft:45}}>实验室、研究院、产学研基地等合作平台、博士流动站</h3>
                        <p style={{fontSize:`0.9vw`,color:`#888`,lineHeight:`2.3`}}>
                            覆盖全球300+帮助10000+大学生：50+垂直理工学生社群，因为Pi Yard发现更好的职业方向
                        </p>
                        <Piyard0311Svg style={{position:`absolute`,bottom:1,left:`4vw`,width:`35vw`,height:`auto`}} />
                        <Piyard01Image3Svg style={{position:`absolute`,bottom:`2.5vw`,right:`45px`,width:`9.5vw`,height:`auto`}} />
                    </div>
                    
                </div>
                <div></div>
            </div>
            {/* 04 */}
            <div className="anchro blackBackground grayBorderBottom" id="part4" style={part4?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`5`}}>
                <div className='whiteFont'>Process</div>
                <div className='whiteFont grayBorder'>04</div>
                <div className='whiteFont'></div>
            </div>
            {
                part4 &&
                <div id="part4_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content blackBackground">
                <div style={{borderBottom:`1px solid #545454`}}></div>
                <div className="piyard_content04 grayBorder" style={{paddingLeft:0,borderBottom:`1px solid #545454`}}>
                    <div>
                        <h3 style={{fontSize:`2.35vw`,color:`#fff`,fontWeight:`bold`,marginTop:`13vw`,marginBottom:`13vw`}}>服务流程</h3>
                        <div>
                            <Piyard0401Svg className="piyard_content04_svg" />
                            <h3 className="piyard_content04_title">建设全方位雇主品牌</h3>
                            <p className="piyard_content04_text">我们去深度了解您的企业价值观、挑战和目标，并以最适合年轻候选人吸收的方式在他们当中传播，这将有助于他们选择加入您的团队，并快速融入文化</p>
                            <img src={Img0401} />
                        </div>
                        <div>
                            <Piyard0403Svg className="piyard_content04_svg" />
                            <h3 className="piyard_content04_title">建立专属人才库</h3>
                            <p className="piyard_content04_text">我们帮您构建企业专属人才库，可对人才资源进行集中存储，通过智能化技术应用，最大化实现企业人才资源的盘活，为企业发展保驾护航，同时降低无效费用支出</p>
                            <img src={Img0401} />
                        </div>
                        <div>
                            <Piyard0405Svg className="piyard_content04_svg" />
                            <h3 className="piyard_content04_title">校招数据分析</h3>
                            <p className="piyard_content04_text">我们使用数据洞察来增强我们的服务，我们依靠人工智能、机器学习和互联网产品的组合，为您提供校招全流程的数据支持</p>
                            <img src={Img0401} />
                        </div>
                    </div>
                    <div>
                        <div style={{marginTop:`47vw`}}>
                            <Piyard0402Svg className="piyard_content04_svg" />
                            <h3 className="piyard_content04_title">JD标准化</h3>
                            <p className="piyard_content04_text">我们根据您的招聘需求，匹配行业同类岗位的职位描述、学生简历维度以及关键字大数据库，帮助您标准化每一份符合您招聘预期的jd，以最小沟通成本快速发布准确需求</p>
                            <img src={Img0401} />
                        </div>
                        <div>
                            <Piyard0404Svg className="piyard_content04_svg" />
                            <h3 className="piyard_content04_title">offer保温</h3>
                            <p className="piyard_content04_text">我们帮助您设计完整有体系的运营策略并落地实施，填补从offer到入职企业与候选人的接触"真空期"，减少您的人才流失，降低校招招聘成本。</p>
                            <img src={Img0401} />
                        </div>
                        <div>
                            <Piyard0406Svg className="piyard_content04_svg" />
                            <h3 className="piyard_content04_title">招聘模型迭代</h3>
                            <p className="piyard_content04_text">我们依据数据分析+真实评价结果，帮您迭代出更高效、更具性价比的的招聘模型和人才解决方案</p>
                            <img src={Img0401} />
                        </div>
                        <Piyard01Image3Svg style={{width:`9.587vw`,height:`auto`,display:`block`,margin:`0 42px 8vw auto`}} />
                    </div>
                </div>
                <div style={{borderBottom:`1px solid #545454`}}></div>
            </div>
        </div>
    )
}

export default PiYard;