import React, { useEffect, useState } from 'react';
import { ReactComponent as Piyard01Image3Svg } from '../assets/piyard01Image3.svg';

import { ReactComponent as Piland0101Svg } from '../assets/piland/0101.svg';
import { ReactComponent as Piland0102Svg } from '../assets/piland/0102.svg';

import { ReactComponent as Piland0201Svg } from '../assets/piland/0201.svg';
import { ReactComponent as Piland0202Svg } from '../assets/piland/0202.svg';
import { ReactComponent as Piland0203Svg } from '../assets/piland/0203.svg';

import { ReactComponent as Piland0401Svg } from '../assets/piland/0401.svg';
import { ReactComponent as Piland0402Svg } from '../assets/piland/0402.svg';
import { ReactComponent as Piland0403Svg } from '../assets/piland/0403.svg';
import { ReactComponent as Piland0404Svg } from '../assets/piland/0404.svg';
import { ReactComponent as Piland0405Svg } from '../assets/piland/0405.svg';
import { ReactComponent as Piland0406Svg } from '../assets/piland/0406.svg';
import { ReactComponent as Piland0504Svg } from '../assets/piland/0504.svg';

import Img00 from '../assets/piland/0301.png';

import Img0204 from '../assets/piland/0204.png';
import Img0205 from '../assets/piland/0205.png';

import Img01 from '../assets/piland/0501.png';
import Img02 from '../assets/piland/0502.png';
import Img03 from '../assets/piland/0503.png';
import Img01w from '../assets/piland/0501_w.png';
import Img02w from '../assets/piland/0502_w.png';
import Img03w from '../assets/piland/0503_w.png';

import QueueAnim from 'rc-queue-anim';
import 'animate.css';


function Piland(props){
    const [part1, setPart1] = useState(false);
    const [part2, setPart2] = useState(false);
    const [part3, setPart3] = useState(false);
    const [part4, setPart4] = useState(false);
    const [init, setInit] = useState(false);

    const [an1, setAn1] = useState(false);
    const [an2, setAn2] = useState(false);
    const [an3, setAn3] = useState(false);
    const [an4, setAn4] = useState(false);
    const [an5, setAn5] = useState(false);
    const [an6, setAn6] = useState(false);
    const [an7, setAn7] = useState(false);
    const [an8, setAn8] = useState(false);

    const [initImgCount, setInitImgCount] = useState(0)

    const styles={
        fontStyle:{
            fontWeight:`700`,
            // fontFamily:`Microsoft YaHei`,
            fontSize:`2.3vw`,
            lineHeight:`40px`,
            color:`#1d1d1d`
        },
        content01:{
            display:`flex`
        },
        piyardFont:{
            margin:`0 0 8px`,
            height:`auto`, 
            width:`27vw`,
            minWidth:240, 
            maxWidth:480,
        },
        content01_text:{
            fontSize:`0.9vw`,
            lineHeight:`2.3`, 
            color:`#888`,
            width:`30vw`,
            minWidth:240, 
            maxWidth:`480px`,
            marginBottom:`200px`
        },
        piyard01Image:{
            height:`32vw`,
            width:`auto`,
            maxHeight:`550px`,
            marginTop:`80px`
        },
        title_03:{
            color:`#1d1d1d`,
            fontSize:`1.4vw`,
            lineHeight:`140%`,
            fontWeight:`bold`,
            marginBottom:`2.2vw`
        },
        content_03:{
            color:`#888`,
            fontSize:`0.95vw`,
            lineHeight:`200%`
        },
        svg_03:{
            width:`4.2vw`,
            height:`4.2vw`,
            marginBottom:`3vw`
        },
        content02Container:{
            background:`#1d1d1d`,
            color:`#fff`,
            padding:`4.3vw 3vw`,
            marginBottom:`18vw`,
        }

    }

    function fixAnchro(p1, p2, p3, p4){
        var t = document.documentElement.scrollTop || document.body.scrollTop;
        // console.log(t,p1,p2,p3,p4)
        if(t<p1){
            window.p1 = false;
            window.p2 = false;
            window.p3 = false;
            window.p4 = false;
            setPart1(false);
            setPart2(false);
            setPart3(false);
            setPart4(false);
        }else
        if(t>=p1 && t<p2 ){
            window.p1 = true;
            window.p2 = false;
            window.p3 = false;
            window.p4 = false;
            setPart1(true);
            setPart2(false);
            setPart3(false);
            setPart4(false);
        }else
        if(t>=p2 && t<p3 ){
            window.p1 = false;
            window.p2 = true;
            window.p3 = false;
            window.p4 = false;
            setPart1(false);
            setPart2(true);
            setPart3(false);
            setPart4(false);
        }else
        if(t>=p3 && t<p4){
            window.p1 = false;
            window.p2 = false;
            window.p3 = true;
            window.p4 = false;
            setPart1(false);
            setPart2(false);
            setPart3(true);
            setPart4(false);
        }else
        if(t>=p4 ){
            window.p1 = false;
            window.p2 = false;
            window.p3 = false;
            window.p4 = true;
            setPart1(false);
            setPart2(false);
            setPart3(false);
            setPart4(true);
        }
    }

    function debonce(fn){
        var timer= null;
        return function(){
            var context = this;
            var args  = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args )
            }, 300)
        }
    }

    function resizeScreen(){
        let newP1 = window.p1?document.getElementById('part1_e').offsetTop - 44:document.getElementById('part1').offsetTop - 44;
        let newP2 = window.p2?document.getElementById('part2_e').offsetTop - 86:document.getElementById('part2').offsetTop - 86;
        let newP3 = window.p3?document.getElementById('part3_e').offsetTop - 86:document.getElementById('part3').offsetTop - 86;
        let newP4 = window.p4?document.getElementById('part4_e').offsetTop - 86:document.getElementById('part4').offsetTop - 86;
        fixAnchro(newP1, newP2, newP3, newP4);
        document.onscroll=()=>{
            debonce(fixAnchro(newP1, newP2, newP3, newP4))
        }
    }

    function hanldeImgLoad(){
        let count = initImgCount + 1;
        setInitImgCount(count)
    }

    useEffect(()=>{
        if(initImgCount <4)return;
        let part1 = document.getElementById('part1').offsetTop - 44;
        let part2 = document.getElementById('part2').offsetTop - 86;
        let part3 = document.getElementById('part3').offsetTop - 86;
        let part4 = document.getElementById('part4').offsetTop - 86;
        // debugger
        // console.log(part3,part4)

        let clientH = document.body.clientHeight;
        let an1ST = document.getElementById('animation1').offsetTop;
        let an2ST = document.getElementById('animation2').offsetTop;
        let an3ST = document.getElementById('animation3').offsetTop;
        let an4ST = document.getElementById('animation4').offsetTop;
        let an5ST = document.getElementById('animation5').offsetTop;
        let an6ST = document.getElementById('animation6').offsetTop;
        let an7ST = document.getElementById('animation7').offsetTop;
        let an8ST = document.getElementById('animation8').offsetTop;

        let an1H = document.getElementById('animation1').clientHeight;
        let an2H = document.getElementById('animation2').clientHeight;
        let an3H = document.getElementById('animation3').clientHeight;
        let an4H = document.getElementById('animation4').clientHeight;
        let an5H = document.getElementById('animation5').clientHeight;
        let an6H = document.getElementById('animation6').clientHeight;
        let an7H = document.getElementById('animation7').clientHeight;
        let an8H = document.getElementById('animation8').clientHeight;
        // let an4H = document.getElementById('animation4').clientHeight;
        fixAnchro(part1,part2,part3,part4);
        document.onscroll=()=>{
            debonce(fixAnchro(part1, part2, part3, part4))
            let newP2 = window.p2?document.getElementById('part2_e').offsetTop - 86:document.getElementById('part2').offsetTop - 86;
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            // console.log(clientH + scrollTop, an1H + part2 + 86)
            if(scrollTop + clientH >= newP2 + an1ST +  an1H * 0.6 + 86 + 44 && !an1 ){
                setAn1(true)
            }
            if(scrollTop + clientH >= newP2 + an2ST +  an2H * 0.6 + 86 + 44 && !an2 ){
                setAn2(true)
            }
            if(scrollTop + clientH >= newP2 + an3ST +  an3H * 0.6 + 86 + 44 && !an3 ){
                setAn3(true)
            }
            if(scrollTop + clientH >= newP2 + an4ST +  an4H * 0.6 + 86 + 44 && !an4 ){
                setAn4(true)
            }
            if(scrollTop + clientH >= newP2 + an5ST +  an5H * 0.6 + 86 + 44 && !an5 ){
                setAn5(true)
            }
            if(scrollTop + clientH >= newP2 + an6ST +  an6H * 0.6 + 86 + 44 && !an6 ){
                setAn6(true)
            }
            if(scrollTop + clientH >= newP2 + an7ST +  an7H * 0.6 + 86 + 44 && !an7 ){
                setAn7(true)
            }
            if(scrollTop + clientH >= newP2 + an8ST +  an8H * 0.6 + 86 + 44 && !an8 ){
                setAn8(true)
            }
        }
        
        window.onresize=()=>{
            debonce(resizeScreen())
        }
        setInit(true);

        return ()=>{
            
        }
    },[initImgCount])

    useEffect(()=>{
        let state = props.history.location.state;
        if(state && state.location && init){
            let scrollTo;
            if(state.location === 'part1'){
                scrollTo = window.p1?document.getElementById('part1_e').offsetTop - 44:document.getElementById('part1').offsetTop - 44;
            }
            if(state.location === 'part2'){
                scrollTo = window.p2?document.getElementById('part2_e').offsetTop - 86:document.getElementById('part2').offsetTop - 86;
            }
            if(state.location === 'part3'){
                scrollTo = window.p3?document.getElementById('part3_e').offsetTop - 86:document.getElementById('part3').offsetTop - 86;
            }
            if(state.location === 'part4'){
                scrollTo = window.p4?document.getElementById('part4_e').offsetTop - 86:document.getElementById('part4').offsetTop - 86;
            }
            window.scrollTo(0,scrollTo)
        }
    },[props.history.location.state,init])

    return (
        <div>
            {/* 01 */}
            <div className="anchro" id="part1" style={part1?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`unset`}}>
                <div>Pi Land</div>
                <div>01</div>
                <div></div>
            </div>
            {
                part1 &&
                <div id="part1_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content">
                <div></div>
                <div className="flex " style={{padding:`50px 0 200px 45px`}}>
                    <div className="flex" style={{flexDirection:`column`}}>
                        <Piland0101Svg style={styles.piyardFont} />
                        <h3 style={{...styles.fontStyle, fontSize:`1.4vw`, marginBottom:70, maxWidth:452,marginTop:20}}>中高端科技人才匹配服务平台</h3>
                        <p style={{...styles.content01_text,maxWidth:`410px`,marginBottom:`18vw`}}>
                        Pi Land依托于国家十四五规划及工业4.0大环境,专注于为企业提供基于企业数字化、智能制造、高科技、新能源、新材料、人工智能等国家重点发展领域及行业的高精尖人才储备&定向挖掘服务。<br/>中高端科技人才招聘只是Pi Land服务的开始,持续为企业和候选人提供价值才是我们的最终目的。
                        </p>
                        <Piyard01Image3Svg style={{marginTop:`auto`,height:`auto`,width:`9.587vw`}} />   
                    </div>
                    <Piland0102Svg style={{height:`27vw`,width:`auto`,margin:`20vw 0 0px 6vw`,alignSelf:`flex-end`}} />
                </div>
                <div></div>
            </div>
            {/* 02 */}
            <div className="anchro" id="part2" style={part2?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`3`}}>
                <div>Object</div>
                <div>02</div>
                <div></div>
            </div>
            {
                part2 &&
                <div id="part2_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content"  style={{position:`relative`}}>
                <div></div>
                <div style={{padding:`90px 7vw`,width:`100%`,paddingBottom:`50px`}}>
                    <div className='flex alignStart justBetween' style={{paddingBottom:66}}>
                        <div id="animation1" className='animation_slide' >
                            <Piland0201Svg style={{width:`43.4vw`,height:`auto`}} />
                            <Piland0201Svg style={{width:`43.4vw`,height:`auto`}} className={an1?'animation_slide_el animation_slide_el_start slideFromBottom slideFromBottomStart' :'animation_slide_el slideFromBottom'} />
                        </div>
                        <div className='animation_slide'>
                            <Piland0202Svg style={{width:`3.3vw`,height:`auto`,marginTop:20}} />
                            <Piland0202Svg style={{width:`3.3vw`,height:`auto`,marginTop:20}} className={an1?'animation_slide_el animation_slide_el_start slideFromRightStart slideFromRight' :'animation_slide_el slideFromRight'} />
                        </div>
                    </div>
                    <div className='flex alignCenter justBetween' style={{paddingBottom:0}}>
                        <div style={{width:`25%`}}>
                            <div className='animation_slide' id="animation2" style={{marginBottom:`2.5vw`}}>
                                <h3 style={{margin:0,fontSize:`2.3vw`,fontWeight:`bold`,lineHeight:`200%`}} >企业服务</h3>
                                <h3 style={{margin:0,fontSize:`2.3vw`,fontWeight:`bold`,lineHeight:`200%`}} className={an2?'animation_slide_el animation_slide_el_start slideFromBottom slideFromBottomStart' :'animation_slide_el slideFromBottom'}  >企业服务</h3>
                            </div>
                            <div className='animation_slide' id="animation3">
                                <p style={{margin:0,fontSize:`0.94vw`,lineHeight:`200%`}} >针对大、中、小科技型企业以及数字化转型企业，通过人才赋能为企业组织赋能</p>
                                <p style={{margin:0,fontSize:`0.94vw`,lineHeight:`200%`}} className={an3?'animation_slide_el animation_slide_el_start slideFromBottom slideFromBottomStart' :'animation_slide_el slideFromBottom'}  >针对大、中、小科技型企业以及数字化转型企业，通过人才赋能为企业组织赋能</p>
                            </div>
                        </div>
                        <div className='animation_slide' id="animation4" style={{width:`40%`}}>
                            <img onLoad={hanldeImgLoad} src={Img0204} style={{width:`100%`}} />
                            <img onLoad={hanldeImgLoad} src={Img0204} style={{width:`100%`}} className={an4?'animation_slide_el animation_slide_el_start slideFromRight slideFromRightStart' :'animation_slide_el slideFromRight'} />
                        </div>
                    </div>
                    <div className='flex justBetween'>
                        <Piyard01Image3Svg style={{width:`9.587vw`,height:`auto`,alignSelf:`flex-start`}} />
                        <div className='animation_slide' id="animation5">
                            <Piland0203Svg style={{width:`34vw`,height:`auto`,margin:`3.5vw 0`}}/>
                            <Piland0203Svg style={{width:`34vw`,height:`auto`,margin:`3.5vw 0`}} className={an5?'animation_slide_el animation_slide_el_start slideFromRightStart slideFromRight' :'animation_slide_el slideFromRight'} />
                        </div>
                    </div>
                    <div className='flex alignCenter justBetween' style={{paddingBottom:0,flexDirection:`row-reverse`}}>
                        <div style={{width:`25%`}}>
                            <div className='animation_slide' id='animation6' style={{marginBottom:`2.5vw`}}>
                                <h3 style={{margin:0,fontSize:`2.3vw`,fontWeight:`bold`,lineHeight:`200%`}} >人才服务</h3>
                                <h3 style={{margin:0,fontSize:`2.3vw`,fontWeight:`bold`,lineHeight:`200%`}} className={an6?'animation_slide_el animation_slide_el_start slideFromBottom slideFromBottomStart' :'animation_slide_el slideFromBottom'}  >人才服务</h3>
                            </div>
                            <div className='animation_slide' id="animation7">
                                <p style={{margin:0,fontSize:`0.94vw`,lineHeight:`200%`}} >为行业领域专家、高精尖科研人才、中高端管理人才、核心研发技术人才提供全流程职业规划&服务</p>
                                <p style={{margin:0,fontSize:`0.94vw`,lineHeight:`200%`}} className={an7?'animation_slide_el animation_slide_el_start slideFromBottom slideFromBottomStart' :'animation_slide_el slideFromBottom'}  >为行业领域专家、高精尖科研人才、中高端管理人才、核心研发技术人才提供全流程职业规划&服务</p>
                            </div>
                        </div>
                        <div className='animation_slide' style={{width:`40%`}} id="animation8">
                            <img onLoad={hanldeImgLoad} src={Img0205} style={{width:`100%`}} />
                            <img onLoad={hanldeImgLoad} src={Img0205} style={{width:`100%`}} className={an8?'animation_slide_el animation_slide_el_start slideFromLeft slideFromLeftStart' :'animation_slide_el slideFromLeft'} />
                        </div>
                    </div>
                    <div className='flex justEnd' style={{paddingBottom:`12vw`}}>
                        <Piyard01Image3Svg style={{width:`9.587vw`,height:`auto`,alignSelf:`flex-start`}} />
                    </div>
                </div>
                <div></div>
            </div>
            {/* 03 */}
            <div className="anchro" id="part3" style={part3?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`4`}}> 
                <div>Solution</div>
                <div>03</div>
                <div></div>
            </div>
            {
                part3 &&
                <div id="part3_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content" >
                <div></div>
                <div style={{padding:`0px 0 0 0px`, position:`relative`}}>
                    <h3 style={{...styles.fontStyle,position:`absolute`,top:`10vw`,left:`9vw`}}>解决方案</h3>
                    <div>
                        <img src={Img00} style={{width:`100%`}} />
                    </div>
                </div>
                <div></div>
            </div>
            {/* 04 */}
            <div className="anchro blackBackground grayBorderBottom" id="part4" style={part4?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`5`}}>
                <div className='whiteFont'>Advantages</div>
                <div className='whiteFont grayBorder'>04</div>
                <div className='whiteFont'></div>
            </div>
            {
                part4 &&
                <div id="part4_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content blackBackground">
                <div style={{borderBottom:`1px solid #545454`}}></div>
                <div className='grayBorder' style={{padding:0,borderBottom:`1px solid #545454`}}>
                    <div className='flex justBetween alignStart'>
                        <h3 style={{...styles.fontStyle,marginTop:142,paddingLeft:45,color:`#fff`}}>行业优势</h3>
                        {/* <Imagine0412Svg style={{width:`3.35vw`,height:`3.35vw`}} /> */}
                        <p style={{borderBottom:`1px solid #545454`,width:`50%`,lineHeight:`200%`,color:`#888`,fontSize:`0.95vw`,marginTop:`142px`,paddingBottom:`6vw`,marginBottom:`8vw`,paddingRight:`45px`}}>
                            <span style={{display:`block`,width:`70%`}} >
                                多年专注高科技行业人才猎寻服务,擅长智能制造、数字化转型、高科技研发、互联网、新零售等领域
                            </span>
                        </p>
                    </div>
                    <div style={{padding:`0 45px`}}>
                        <div className='flex alignStart justBetween' style={{marginBottom:`16px`}}>
                            <Piland0401Svg style={{width:`60vw`,height:`auto`}} />
                            <h3 style={{lineHeight:`140%`,fontSize:`2.35vw`,color:`#e5e5e5`,marginBottom:0,fontWeight:`bold`}}>
                                团队优势
                            </h3>
                        </div>
                        <Piland0402Svg style={{width:`3.3vw`,height:`auto`,display:`block`,marginLeft:`auto`,marginBottom:`40px`}} />
                    </div>
                    <div className='flex' style={{padding:`0 45px`, position:`relative`}}>
                        <div style={{marginRight:`15vw`}}>
                            <div style={{marginBottom:`21.5vw`}}>
                                <Piland0403Svg style={{width:`13.8vw`,height:`auto`}} />
                                <h4 style={{fontSize:`1.4vw`,marginBottom:0,lineHeight:`200%`,marginBottom:`12px`,fontWeight:`bold`,color:`#e5e5e5`}}>
                                    高精尖人才精准交付
                                </h4>
                                <p style={{width:`18vw`,fontSize:`0.95vw`,lineHeight:`200%`,color:`#888`}}>
                                    15年人力资源行业高精尖人才精准猎聘及全方位服务。
                                </p>
                            </div>
                            <div>
                                <Piland0405Svg style={{width:`13.8vw`,height:`auto`}} />
                                <h4 style={{fontSize:`1.4vw`,marginBottom:0,lineHeight:`200%`,marginBottom:`12px`,fontWeight:`bold`,color:`#e5e5e5`}}>
                                    全链路供应链经验
                                </h4>
                                <p style={{width:`18vw`,fontSize:`0.95vw`,lineHeight:`200%`,color:`#888`}}>
                                    智能制造全链条供应链管理经验及工业4.0精益生产管理 
                                </p>
                            </div>
                        </div>
                        <div>
                            <div style={{marginBottom:`21.5vw`,marginTop:`20vw`}}>
                                <Piland0404Svg style={{width:`13.8vw`,height:`auto`}} />
                                <h4 style={{fontSize:`1.4vw`,marginBottom:0,lineHeight:`200%`,marginBottom:`12px`,fontWeight:`bold`,color:`#e5e5e5`}}>
                                    专家团队领衔
                                </h4>
                                <p style={{width:`18vw`,fontSize:`0.95vw`,lineHeight:`200%`,color:`#888`}}>
                                    交付团队80%为业务出身,均为各领域专家,对行业领域人才及业务深刻理解与准确分析
                                </p>
                            </div>
                            <div style={{marginBottom:`21.5vw`}}>
                                <Piland0406Svg style={{width:`13.8vw`,height:`auto`}} />
                                <h4 style={{fontSize:`1.4vw`,marginBottom:0,lineHeight:`200%`,marginBottom:`12px`,fontWeight:`bold`,color:`#e5e5e5`}}>
                                    需求精准定位
                                </h4>
                                <p style={{width:`18vw`,fontSize:`0.95vw`,lineHeight:`200%`,color:`#888`}}>
                                    深入业务,深度访谈,需求剖析,精准定位人才需求&人才画像
                                </p>
                            </div>
                        </div>
                        <Piyard01Image3Svg style={{width:`9.587vw`,height:`auto`,position:`absolute`,bottom:`45px`,right:`45px`}} />
                    </div>
                </div>
                <div style={{borderBottom:`1px solid #545454`}}></div>
            </div>
            <div className="anchro_content blackBackground">
                <div></div>
                <div className='grayBorder' style={{borderBottom:0}}>
                    <h3 style={{...styles.fontStyle,marginTop:142,paddingLeft:0,color:`#fff`,marginBottom:`9vw`}}>
                        技术优势
                    </h3>
                    <ul className="piland05Ul">
                        <li>
                            <img src={Img01}  className="img_r" />
                            <img src={Img01w} className="img_w" />
                            <div>
                                <h3>RPA自动化</h3>
                                <p>主流招聘渠道24小时自动化找人,精准度&gt;70% </p>
                            </div>
                            <div className="boxAnimation"></div>
                        </li>
                        <li>
                            <img src={Img02}  className="img_r" />
                            <img src={Img02w} className="img_w" />
                            <div>
                                <h3>Onsite本地化</h3>
                                <p>根据客户需求Onsite全流程服务</p>
                            </div>
                            <div className="boxAnimation"></div>
                        </li>
                        <li>
                            <img src={Img03}  className="img_r" />
                            <img src={Img03w} className="img_w" />
                            <div>
                                <h3>RPA自动化</h3>
                                <p>高精尖人才定向mapping&挖掘(行业,公司,团队) </p>
                            </div>
                            <div className="boxAnimation"></div>
                        </li>
                    </ul>
                    <Piland0504Svg  style={{marginRight:45,width:`70%`,height:`auto`,display:`block`,marginLeft:`auto`}} />
                </div>
                <div style={{borderBottom:0}}></div>
            </div>
        </div>
    )
}

export default Piland;