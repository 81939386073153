import React from "react";
import { BrowserRouter, Switch, Route, HashRouter  } from "react-router-dom";
import MainLayout from "./component/command/MainLayout";
import { ROUTE_CONTACTUS, ROUTE_HOME, ROUTE_IMAGINE, ROUTE_PILAND, ROUTE_PIYARD, ROUTE_RESPONSIBILITY } from "./constant/routeConstant";


function App() {
  return (
    <div>
        <HashRouter  basename="" >
            <Switch>
                <Route pathname={ROUTE_HOME} component={MainLayout}></Route>
                <Route pathname={ROUTE_PIYARD} component={MainLayout}></Route>
                <Route pathname={ROUTE_IMAGINE} component={MainLayout}></Route>
                <Route pathname={ROUTE_PILAND} component={MainLayout}></Route>
                <Route pathname={ROUTE_RESPONSIBILITY} component={MainLayout}></Route>
                <Route pathname={ROUTE_CONTACTUS} component={MainLayout}></Route>
            </Switch>
        </HashRouter>
    </div>
  );
}

export default App;
