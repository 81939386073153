import Header from "./command/Header";
import React, { useEffect, useState } from 'react';
import { ReactComponent as Home0101Svg } from '../assets/home/0101.svg';
import { ReactComponent as Home0102Svg } from '../assets/home/0102.svg';
import { ReactComponent as Home0207Svg } from '../assets/home/0207.svg';
import { ReactComponent as Home0208Svg } from '../assets/home/0208.svg';
import { ReactComponent as Home0301Svg } from '../assets/home/0301.svg';
import { ReactComponent as Home0302Svg } from '../assets/home/0302.svg';
import { ReactComponent as Home0401Svg } from '../assets/home/0401.svg';
import { ReactComponent as Home0402Svg } from '../assets/home/0402.svg';
import { ReactComponent as Home0403Svg } from '../assets/home/0403.svg';
import { ReactComponent as Home0404Svg } from '../assets/home/0404.svg';

import { ReactComponent as Piyard01Image3Svg } from '../assets/piyard01Image3.svg';

import Img01 from '../assets/home/0201.png';
import Img02 from '../assets/home/0202.png';
import Img03 from '../assets/home/0203.png';
import Img04 from '../assets/home/0204.png';
import Img05 from '../assets/home/0205.png';
import Img06 from '../assets/home/0206.png';
import Img01w from '../assets/home/0201_w.png';
import Img02w from '../assets/home/0202_w.png';
import Img03w from '../assets/home/0203_w.png';
import Img04w from '../assets/home/0204_w.png';
import Img05w from '../assets/home/0205_w.png';
import Img06w from '../assets/home/0206_w.png';



function Home(props){
    const [part1, setPart1] = useState(false);
    const [part2, setPart2] = useState(false);
    const [part3, setPart3] = useState(false);
    const [part4, setPart4] = useState(false);
    
    const styles={
        fontStyle:{
            fontWeight:`700`,
            // fontFamily:`Microsoft YaHei`,
            fontSize:`2.3vw`,
            lineHeight:`40px`,
            color:`#1d1d1d`
        },
        content01:{
            display:`flex`
        },
        piyardFont:{
            margin:`0 0 8px`,
            height:`auto`, 
            width:`42vw`,
            minWidth:240, 
            // maxWidth:480,
        },
        content01_text:{
            fontSize:`0.9vw`,
            lineHeight:`2.3`, 
            color:`#888`,
            width:`30vw`,
            minWidth:240, 
            maxWidth:`480px`,
            marginBottom:`200px`
        },
        piyard01Image:{
            height:`32vw`,
            width:`auto`,
            maxHeight:`550px`,
            marginTop:`80px`
        },

    }

    function fixAnchro(p1, p2, p3, p4){
        var t = document.documentElement.scrollTop || document.body.scrollTop;
        if(t<p1){
            window.p1 = false;
            window.p2 = false;
            window.p3 = false;
            window.p4 = false;
            setPart1(false);
            setPart2(false);
            setPart3(false);
            setPart4(false);
        }else
        if(t>=p1 && t<p2 ){
            window.p1 = true;
            window.p2 = false;
            window.p3 = false;
            window.p4 = false;
            setPart1(true);
            setPart2(false);
            setPart3(false);
            setPart4(false)
        }else
        if(t>=p2 && t<p3 ){
            window.p1 = false;
            window.p2 = true;
            window.p3 = false;
            window.p4 = false;
            setPart1(false);
            setPart2(true);
            setPart3(false);
            setPart4(false)
        }else
        if(t>=p3 && t<p4){
            window.p1 = false;
            window.p2 = false;
            window.p3 = true;
            window.p4 = false;
            setPart1(false);
            setPart2(false);
            setPart3(true);
            setPart4(false)
        }else
        if(t>=p4 ){
            window.p1 = false;
            window.p2 = false;
            window.p3 = false;
            window.p4 = true;
            setPart1(false);
            setPart2(false);
            setPart3(false);
            setPart4(true)
        }
    }

    function debonce(fn){
        var timer= null;
        return function(){
            var context = this;
            var args  = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args )
            }, 300)
        }
    }

    function resizeScreen(){
        let newP1 = window.p1?document.getElementById('part1_e').offsetTop - 44:document.getElementById('part1').offsetTop - 44;
        let newP2 = window.p2?document.getElementById('part2_e').offsetTop - 86:document.getElementById('part2').offsetTop -86;
        let newP3 = window.p3?document.getElementById('part3_e').offsetTop - 86:document.getElementById('part3').offsetTop -86;
        let newP4 = window.p4?document.getElementById('part4_e').offsetTop - 86:document.getElementById('part4').offsetTop -86;
        fixAnchro(newP1, newP2, newP3, newP4);
        document.onscroll=()=>{
            debonce(fixAnchro(newP1, newP2, newP3, newP4))
        }
    }

    useEffect(()=>{
        let part1 = document.getElementById('part1').offsetTop - 44;
        let part2 = document.getElementById('part2').offsetTop - 86;
        let part3 = document.getElementById('part3').offsetTop - 86;
        let part4 = document.getElementById('part4').offsetTop - 86;
        fixAnchro(part1,part2,part3,part4);
        document.onscroll=()=>{
            debonce(fixAnchro(part1, part2, part3, part4))
        }

        window.onresize=()=>{
            debonce(resizeScreen())
        }
    },[])

    return (
        <div>
            {/* 01 */}
            <div className="anchro" id="part1" style={part1?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`unset`}}>
                <div>Intro</div>
                <div>01</div>
                <div></div>
            </div>
            {
                part1 &&
                <div id="part1_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content">
                <div></div>
                <div style={{padding:`50px 0 0 45px`}}>
                    <Home0102Svg style={styles.piyardFont} />
                    <h3 style={{...styles.fontStyle, lineHeight:`21px`,fontSize:`1.05vw`,textTransform:`uppercase`}}>Vision the impossible</h3>
                    <h4 style={{color:`#EB483F`,fontSize:`2.3vw`,marginBottom:`25px`,lineHeight:`170%`,fontWeight:`bold`}}>深空裂变</h4>
                    <div style={{...styles.content01,alignItems:`flex-end`,marginBottom:`95px`}}>
                        <Piyard01Image3Svg style={{width:`9.5vw`,height:`auto`,marginRight:`4.35vw`,maxWidth:`160px`}} />
                        <Home0101Svg style={{width:`52vw`,height:`auto`,maxWidth:`950px`}} />
                    </div>
                    <div className="home01_bottomTxt">
                        <span>Technology</span>
                        <span>Talents matching</span>
                        <span>Recruitment</span>
                    </div>
                </div>
                <div></div>
            </div>
            {/* 02 */}
            <div className="anchro" id="part2" style={part2?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`3`}}>
                <div>Industries</div>
                <div>02</div>
                <div></div>
            </div>
            {
                part2 &&
                <div id="part2_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content"  style={{position:`relative`}}>
                <div></div>
                <div style={{padding:`50px 0 0px 0px`}}>
                    <div style={{...styles.content01,paddingLeft:45,marginBottom:`10vw`, marginTop:92,width:`85%`,justifyContent:`space-between`}}>
                        <h3 style={{...styles.fontStyle, lineHeight:`140%`}}>
                            高科技人才<br/>匹配服务平台
                        </h3>
                        <p style={{...styles.content01_text,maxWidth:730, width:`43%`,margin:0}}>
                            以人工智能、大数据、云计算、物联网为代表的数字智能技术与传统产业深度融合既是未来趋势所在，也是提升效率效益的必然选择。作为一家融合了制造业和互联网人力基因的创新公司，我们专注于为智能产业上下游的客户提供中高端人才及校招人才解决方案
                        </p>
                    </div>
                    <div>
                        <div style={{background:`#dcdcdc`,padding:`45px`}}>
                            <ul className="piyard02Ul home02Ul">
                                <li>
                                    <img src={Img01}  className="img_r" />
                                    <img src={Img01w} className="img_w" />
                                    <span>智能制造</span>
                                    <i>01</i>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img02}  className="img_r" />
                                    <img src={Img02w} className="img_w" />
                                    <span>人工智能</span>
                                    <i>02</i>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img03}  className="img_r" />
                                    <img src={Img03w} className="img_w" />
                                    <span>新能源</span>
                                    <i>03</i>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img04}  className="img_r" />
                                    <img src={Img04w} className="img_w" />
                                    <span>智慧医疗</span>
                                    <i>04</i>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img05}  className="img_r" />
                                    <img src={Img05w} className="img_w" />
                                    <span>物联网</span>
                                    <i>05</i>
                                    <div className="boxAnimation"></div>
                                </li>
                                <li>
                                    <img src={Img06}  className="img_r" />
                                    <img src={Img06w} className="img_w" />
                                    <span>机器人</span>
                                    <i>06</i>
                                    <div className="boxAnimation"></div>
                                </li>
                            </ul>
                            <Home0207Svg style={{width:`49vw`,height:`auto`}}/>
                        </div>
                        <div className="flex alignStart justBetween" style={{padding:`45px`}}>
                            <Home0208Svg style={{width:`50vw`,height:`auto`}} />
                            <Piyard01Image3Svg style={{width:`9.5vw`,height:`auto`}} />
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            {/* 03 */}
            <div className="anchro" id="part3" style={part3?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`4`}}> 
                <div>Services</div>
                <div>03</div>
                <div></div>
            </div>
            {
                part3 &&
                <div id="part3_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content" >
                <div></div>
                <div style={{padding:`50px 0 0 0px`, position:`relative`}}>
                    <div style={{...styles.content01,paddingLeft:45,marginBottom:`20px`, marginTop:92,width:`85%`,justifyContent:`space-between`}}>
                        <h3 style={{...styles.fontStyle, lineHeight:`140%`}}>
                            服务类目
                        </h3>
                        <p style={{...styles.content01_text,maxWidth:730, width:`43%`,margin:0}}>
                            以人工智能、大数据、云计算、物联网为代表的数字智能技术与传统产业深度融合既是未来趋势所在，也是提升效率效益的必然选择。作为一家融合了制造业和互联网人力基因的创新公司，我们专注于为智能产业上下游的客户提供中高端人才及校招人才解决方案
                        </p>
                    </div>
                    <div className="flex justBetween alignEnd" style={{paddingLeft:45,paddingBottom:75,position:`relative`}} >
                        <Home0301Svg style={{marginBottom:70,width:`auto`,height:`35vw`}} />
                        <div style={{width:`58%`,marginBottom:40}} >
                            <ul className="home03Ul">
                                <li>
                                    <div>01</div>
                                    <div>
                                        <span>Pi Yard</span>
                                        <p>校招匹配&服务平台</p>
                                        <Home0302Svg />
                                    </div>
                                    <i className="boxAnimation"></i>
                                </li>
                                <li>
                                    <div>02</div>
                                    <div>
                                        <span>Imagine</span>
                                        <p>科技企业雇主品牌服务</p>
                                        <Home0302Svg />
                                    </div>
                                    <i className="boxAnimation"></i>
                                </li>
                                <li>
                                    <div>03</div>
                                    <div>
                                        <span>Pi Land</span>
                                        <p>中高端科技人才猎寻</p>
                                        <Home0302Svg  />
                                    </div>
                                    <i className="boxAnimation"></i>
                                </li>
                            </ul>
                        </div>
                        <Piyard01Image3Svg style={{width:`9.5vw`,height:`auto`,position:`absolute`,bottom:`75px`,left:`45px`}} />
                    </div>
                    
                </div>
                <div></div>
            </div>
            {/* 04 */}
            <div className="anchro" id="part4" style={part4?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`relative`,zIndex:`5`}}>
                <div>Technology</div>
                <div>04</div>
                <div></div>
            </div>
            {
                part4 &&
                <div id="part4_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content">
                <div></div>
                <div style={{padding:0}}>
                    <div className="flex justBetween" style={{position:`relative`,borderBottom:`1px solid #c0c0c0`,paddingLeft:45}}>
                        <div style={{marginTop:92,width:`27vw`}}>
                            <h3 style={{...styles.fontStyle, marginBottom:70, }}>人才画像</h3>
                            <p style={{...styles.content01_text,maxWidth:730, width:`95%`,marginBottom:97}}>
                                以人工智能、大数据、云计算、物联网为代表的数字智能技术与传统产业深度融合既是未来趋势所在，也是提升效率效益的必然选择。作为一家融合了制造业和互联网人力基因的创新公司，我们专注于为智能产业上下游的客户提供中高端人才及校招人才解决方案
                            </p>
                        </div>
                        <Home0401Svg style={{width:`20.5vw`,height:`auto`,marginTop:`17.3vw`,marginRight:`15vw`,marginBottom:`10.2vw`}} />
                        <i className="home04_content_line" style={{background:`#EB483F`}}></i>
                        <i className="home04_content_line" style={{top:`25%`}}></i>
                        <i className="home04_content_line" style={{top:`50%`}}></i>
                        <i className="home04_content_line" style={{top:`75%`}}></i>
                    </div>
                    <div className="flex justBetween" style={{position:`relative`,borderBottom:`1px solid #c0c0c0`,paddingLeft:45}}>
                        <div style={{marginTop:92,width:`27vw`}}>
                            <h3 style={{...styles.fontStyle, marginBottom:70, }}>JD标准化</h3>
                            <p style={{...styles.content01_text,maxWidth:730, width:`95%`,marginBottom:97}}>
                                我们根据您的招聘需求，匹配行业同类岗位的职位描述、学生简历维度以及关键字大数据库，帮助您标准化每一份符合您招聘预期的jd，以最小沟通成本快速发布准确需求
                            </p>
                        </div>
                        <Home0402Svg style={{width:`20.5vw`,height:`auto`,marginTop:`17.3vw`,marginRight:`15vw`,marginBottom:`10.2vw`}} />
                        <i className="home04_content_line" ></i>
                        <i className="home04_content_line" style={{top:`25%`,background:`#EB483F`}}></i>
                        <i className="home04_content_line" style={{top:`50%`}}></i>
                        <i className="home04_content_line" style={{top:`75%`}}></i>
                    </div>
                    <div className="flex justBetween" style={{position:`relative`,borderBottom:`1px solid #c0c0c0`,paddingLeft:45}}>
                        <div style={{marginTop:92,width:`27vw`}}>
                            <h3 style={{...styles.fontStyle, marginBottom:70, }}>校招IT人才精准化推送</h3>
                            <p style={{...styles.content01_text,maxWidth:730, width:`95%`,marginBottom:97}}>
                                以人工智能、大数据、云计算、物联网为代表的数字智能技术与传统产业深度融合既是未来趋势所在，也是提升效率效益的必然选择。作为一家融合了制造业和互联网人力基因的创新公司，我们专注于为智能产业上下游的客户提供中高端人才及校招人才解决方案
                            </p>
                        </div>
                        <Home0403Svg style={{width:`20.5vw`,height:`auto`,marginTop:`17.3vw`,marginRight:`15vw`,marginBottom:`10.2vw`}} />
                        <i className="home04_content_line" ></i>
                        <i className="home04_content_line" style={{top:`25%`}}></i>
                        <i className="home04_content_line" style={{top:`50%`,background:`#EB483F`}}></i>
                        <i className="home04_content_line" style={{top:`75%`}}></i>
                    </div>
                    <div className="flex justBetween" style={{position:`relative`,paddingLeft:45}}>
                        <div style={{marginTop:92,width:`27vw`}}>
                            <h3 style={{...styles.fontStyle, marginBottom:70, }}>人才库管理</h3>
                            <p style={{...styles.content01_text,maxWidth:730, width:`95%`,marginBottom:97}}>
                                我们帮您构建企业专属人才库，可对人才资源进行集中存储，通过智能化技术应用，最大化实现企业人才资源的盘活，为企业发展保驾护航，同时降低无效费用支出
                            </p>
                        </div>
                        <Home0404Svg style={{width:`20.5vw`,height:`auto`,marginTop:`17.3vw`,marginRight:`15vw`,marginBottom:`10.2vw`}} />
                        <i className="home04_content_line" ></i>
                        <i className="home04_content_line" style={{top:`25%`}}></i>
                        <i className="home04_content_line" style={{top:`50%`}}></i>
                        <i className="home04_content_line" style={{top:`75%`,background:`#EB483F`}}></i>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default Home;