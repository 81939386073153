import Header from "./command/Header";
import React, { useEffect, useState } from 'react';
import { ReactComponent as Res0101Svg } from '../assets/res/0101.svg';
import { ReactComponent as Res0103Svg } from '../assets/res/0103.svg';
import { ReactComponent as Res0105Svg } from '../assets/res/0105.svg';
import { ReactComponent as Res0106Svg } from '../assets/res/0106.svg';

import { ReactComponent as Piyard01Image3Svg } from '../assets/piyard01Image3.svg';

import Img01 from '../assets/res/0102.png';
import Img02 from '../assets/res/0104.png';



function Responsibility(props){
    const [part1, setPart1] = useState(false);
    
    const styles={
        fontStyle:{
            fontWeight:`700`,
            // fontFamily:`Microsoft YaHei`,
            fontSize:`2.3vw`,
            lineHeight:`40px`,
            color:`#1d1d1d`
        },
        content01:{
            display:`flex`
        },
        piyardFont:{
            margin:`0 0 8px`,
            height:`auto`, 
            width:`27vw`,
            minWidth:240, 
            maxWidth:480,
        },
        content01_text:{
            fontSize:`0.9vw`,
            lineHeight:`2.3`, 
            color:`#888`,
            width:`30vw`,
            minWidth:240, 
            maxWidth:`480px`,
            marginBottom:`200px`
        },
        piyard01Image:{
            height:`32vw`,
            width:`auto`,
            maxHeight:`550px`,
            marginTop:`80px`
        },

    }

    function fixAnchro(p1){
        var t = document.documentElement.scrollTop || document.body.scrollTop;
        if(t<p1){
            window.p1 = false;
            setPart1(false);
        }else
        if(t>=p1){
            window.p1 = true;
            setPart1(true);
        }
    }

    function debonce(fn){
        var timer= null;
        return function(){
            var context = this;
            var args  = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args )
            }, 300)
        }
    }

    function resizeScreen(){
        let newP1 = window.p1?document.getElementById('part1_e').offsetTop - 44:document.getElementById('part1').offsetTop - 44;
        fixAnchro(newP1);
        document.onscroll=()=>{
            debonce(fixAnchro(newP1))
        }
    }

    useEffect(()=>{
        let part1 = document.getElementById('part1').offsetTop - 44;
        fixAnchro(part1);
        document.onscroll=()=>{
            debonce(fixAnchro(part1))
        }

        window.onresize=()=>{
            debonce(resizeScreen())
        }
    },[])

    return (
        <div>
            {/* 01 */}
            <div className="anchro" id="part1" style={part1?{position:`fixed`,top:86,width:`100%`,zIndex:`2`,background:`#e5e5e5`}:{position:`unset`}}>
                <div style={{fontSize:16}}>Responsibility </div>
                <div>01</div>
                <div></div>
            </div>
            {
                part1 &&
                <div id="part1_e" style={{paddingBottom:44}}></div>
            }
            <div className="anchro_content">
                <div></div>
                <div className="res01content" style={{padding:0,position:`relative`}}>
                    <div>
                        <div style={{padding:`60px 60px 0`,marginBottom:`22vw`}}>
                            <Res0105Svg style={{width:`3.35vw`,height:`auto`,marginBottom:`11.35vw`}} />
                            <h3 style={{color:`#1D1D1D`,fontWeight:`bold`,fontSize:`2.35vw`,marginBottom:`1.2vw`}}>社会责任</h3>
                            <h4 style={{color:`#EB483F`,fontWeight:`bold`,fontSize:`1.4vw`,marginBottom:`2.8vw`}}>工业4.0+中国制造2025</h4>
                            <p style={{color:`#888`,fontSize:`0.95vw`,marginBottom:0,lineHeight:`200%`}}>
                                我们的目的，是在社会中建立信任，解决人才和岗位匹配的重要问题，与我们的价值观相结合，为我们的行为和行动提供最高层次的指导，同时，我们通过帮助员工、客户和合作伙伴充分发挥潜力来支持他们
                            </p>
                        </div>
                        <Res0103Svg style={{width:`97%`,height:`auto`,marginBottom:`6.5vw`}} />
                        <img src={Img02} style={{position:`relative`,zIndex:`1`,width:`100%`,marginBottom:`12.8vw`}} />
                    </div>
                    <div>
                        <Res0101Svg style={{width:`90%`,height:`auto`,marginBottom:`6.5vw`,marginTop:`88px`}} />
                        <img src={Img01} style={{position:`relative`,zIndex:`1`,width:`100%`,marginBottom:`12.8vw`}} />
                        <div style={{position:`relative`,padding:`60px 60px 0`,borderTop:`1px solid #c0c0c0`}}>
                            <Res0106Svg style={{width:`3.35vw`,height:`auto`,margin:`0 0 11.35vw auto`,display:`block`}} />
                            <h3 style={{color:`#1D1D1D`,fontWeight:`bold`,fontSize:`2.35vw`,marginBottom:`4vw`}}>数据安全</h3>
                            <p style={{color:`#888`,fontSize:`0.95vw`,marginBottom:0,lineHeight:`200%`}}>
                                我们遵从欧盟的GDPR标准，严格遵守企业及人才的数据安全和隐私保护问题，为客户企业及人才打造更安全的职业发展生态环境，建立更具社会责任感及公信力的企业形象。
                            </p>
                            <Piyard01Image3Svg style={{position:`absolute`,width:`9.587vw`,height:`7.4vw`,top:`calc(-3.7vw - 12.8vw)`,right:`-4.7935vw`}} />
                            <div style={{position:`absolute`,width:`calc(100% + 10vw)`,height:`1px`,background:`#c0c0c0`,top:`0`,left:`0`}}></div>
                        </div>
                    </div>
                    <Piyard01Image3Svg style={{position:`absolute`,width:`9.587vw`,height:`7.4vw`,bottom:`calc(12.8vw - 3.7vw)`,left:`50%`,transform:`translateX(-50%)`}} />
                </div>
                <div></div>
            </div>
            
        </div>
    )
}

export default Responsibility;