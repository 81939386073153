import { ROUTE_CONTACTUS, ROUTE_HOME, ROUTE_IMAGINE, ROUTE_PILAND, ROUTE_PIYARD, ROUTE_RESPONSIBILITY } from "../../constant/routeConstant";
import PiYard from "../PiYard";
import Header from "./Header";
import { Route } from 'react-router-dom';
import Footer from "./Footer";
import Home from "../Home";
import { useEffect } from "react";
import Responsibility from "../Responsibility";
import Contact from "../Contact";
import Imagine from "../Imagine";
import Piland from "../Piland";

function MainLayout(props){

    useEffect(()=>{
        if(!props.location.state){

            if(document.documentElement.scrollTop)document.documentElement.scrollTop =0;
            if(document.body.scrollTop)document.body.scrollTop = 0;
        }
    },[props.location.pathname])

    return (
        <div className="mainLayout">
            <Header history={props.history} />
            <Route exact path={ROUTE_HOME} component={Home} />
            <Route exact path={ROUTE_PIYARD} component={PiYard} />
            <Route exact path={ROUTE_RESPONSIBILITY} component={Responsibility} />
            <Route exact path={ROUTE_PILAND} component={Piland} />
            <Route exact path={ROUTE_IMAGINE} component={Imagine} />
            <Route exact path={ROUTE_CONTACTUS} component={Contact} /> 
            <Footer history={props.history} />
        </div>
    )
}


export default MainLayout;