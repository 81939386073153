import { ReactComponent as FooterArrowSvg } from '../../assets/footer_arrow.svg';
import { ReactComponent as LogoSvg } from '../../assets/logo_footer.svg';
import { ReactComponent as EmailSvg } from '../../assets/footer_email.svg';
import { ReactComponent as MobileSvg } from '../../assets/footer_mobile.svg';
import { Link } from 'react-router-dom';
import { ROUTE_IMAGINE, ROUTE_PILAND, ROUTE_PIYARD } from '../../constant/routeConstant';

function Footer(props){
    return (
        <div className="footer">
            <div className="footer_content">
                <div className='footerIcon'>
                    <FooterArrowSvg style={{width:`3.4vw`,height:`auto`}} />
                    <LogoSvg style={{width:`9vw`,height:`auto`}} />
                </div>
                <div className='flex justBetween alignCenter'>
                    <div className='footer_contactus'>
                        <h2>联系我们</h2>
                        <p><EmailSvg style={{marginRight:20,width:`1.6vw`,height:`auto`}} />jessie@103folds.com</p>
                        <p><MobileSvg style={{marginRight:20,width:`1.6vw`,height:`auto`}} />+86 13434482281</p>
                    </div>
                    <div className='footer_title_container'>
                        <div className='footer_title'>
                            <h3>Pi Yard</h3>
                            {/* <p>校招匹配&服务平台</p> */}
                            <div>
                                <Link to={{pathname:ROUTE_PIYARD,state:{location:`part1`}}}  >关于Pi Yard</Link>
                                <Link to={{pathname:ROUTE_PIYARD,state:{location:`part2`}}}  >行业人才池</Link>
                                <Link to={{pathname:ROUTE_PIYARD,state:{location:`part3`}}}  >校园网络</Link>
                                <Link to={{pathname:ROUTE_PIYARD,state:{location:`part4`}}}  >服务流程</Link>
                            </div>
                        </div>
                        <div className='footer_title'>
                            <h3>IMAGINE</h3>
                            {/* <p>科技企业雇主品牌服务</p> */}
                            <div>
                                <Link to={{pathname:ROUTE_IMAGINE,state:{location:`part1`}}} >关于IMAGINE</Link>
                                <Link to={{pathname:ROUTE_IMAGINE,state:{location:`part2`}}} >服务重点</Link>
                                <Link to={{pathname:ROUTE_IMAGINE,state:{location:`part3`}}} >服务流程</Link>
                                <Link to={{pathname:ROUTE_IMAGINE,state:{location:`part4`}}} >服务案例</Link>
                            </div>
                        </div>
                        <div className='footer_title'>
                            <h3>Pi Land</h3>
                            {/* <p>中高端科技人才猎寻</p> */}
                            <div>
                                <Link to={{pathname:ROUTE_PILAND,state:{location:`part1`}}} >关于Pi Land</Link>
                                <Link to={{pathname:ROUTE_PILAND,state:{location:`part2`}}} >服务对象</Link>
                                <Link to={{pathname:ROUTE_PILAND,state:{location:`part3`}}} >解决方案</Link>
                                <Link to={{pathname:ROUTE_PILAND,state:{location:`part4`}}} >行业优势</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>© Copyright 2015 - 2022 Yourator. ALL Right Reserved. 3.64.0</p>
                <p>
                    <a>使用条款</a>
                    <a style={{marginLeft:80}}>隐私政策</a>
                </p>
            </div>
        </div>
    )
}

export default Footer;